import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import SearchIcon from "components/Common/Icons/SettingIcons/SearchIcon"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../config"
import { useSelector } from "react-redux"

import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"

import { useLocation, useMatch, useNavigate, useParams } from "react-router-dom"
import AddCategories from "./TicketCategories/AddCategories"
import { Toast, ToastBody, ToastHeader } from "reactstrap"
import StatusesTable from "./TicketStatusComponents/StatusesTable"
import AddStatus from "./TicketStatusComponents/AddStatus"
import EditStatus from "./TicketStatusComponents/EditStatus"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function TicketStatus() {
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [showEditToast, setShowEditToast] = useState(false)

  const dispatch = useDispatch()

  const [modalAddNewStatus, setModalAddNewStatus] = useState(false)
  const toggleAdd = () => {
    setModalAddNewStatus(!modalAddNewStatus)
  }

  const [modalEditStatus, setModalEditStatus] = useState(false)
  const [editStatus, setEditStatus] = useState(null)
  const toggleEdit = status => {
    setEditStatus(status)
    setModalEditStatus(!modalEditStatus)
  }

  const [isloading, setIsLoading] = useState(false)
  const [statusesList, setStatusesList] = useState([])

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const fetchAllStatuses = async () => {
    try {
      setIsLoading(true)
      axios
        .get(`${Url}/${apiVersion}/ticketStatuses`, {
          headers: { Authorization: `Bearer ${access}` },
        })
        .then(response => {
          const AllStatuses = response.data.data.statuses

          setStatusesList(AllStatuses)
          setIsLoading(false)
          // console.log("statuses list :::", statusesList)
        })
        .catch(err => {
          if (
            err.response?.status == 401 ||
            err.response?.status == 0 ||
            !err.response?.status
          ) {
            dispatch(unAuthUser())
          } else {
            console.log(err)
            toast.error("Failed to fetch statuses. Please try again later.")
          }
        })
    } catch (error) {
      console.error("Unexpected error:", error)
      toast.error("An unexpected error occurred.")
    }
  }

  useEffect(() => {
    fetchAllStatuses()
  }, [])

  //search:
  const [searchTerm, setSearchTerm] = useState("")
  const handleSearchChange = event => {
    setSearchTerm(event.target.value)
  }
  const filteredStatuses = statusesList.filter(category =>
    category?.name?.toLowerCase().includes(searchTerm.toLowerCase())
  )

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className="user-setting-page">
      {/* add new categories */}
      <Row className="d-flex justify-content-between align-items-center users-setting">
        <p>
          Ticket <span className="mx-1">&#62;</span>
          <span className="users-setting-span"> Statuses</span>
        </p>
      </Row>
      <Row className="d-flex justify-content-between align-items-center mb-3">
        <Col className="input-group input-group-sm bg-white border-0">
          <div className="input-group-prepend bg-white border-0 users-setting">
            <p
              className="input-group-text bg-white border-0 d-flex gap-2 "
              id="inputGroup-sizing-sm"
            >
              <SearchIcon /> Search
            </p>
          </div>
          <input
            type="text"
            className="form-control bg-white border border-0 rounded rounded-2"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
        <Col
          md={8}
          className="d-flex justify-content-end gap-2 align-items-center"
        >
          <button
            className="btn btn-secondary py-1 font-size-16 mb-0 rounded-2"
            onClick={toggleAdd}
          >
            + Add New Status
          </button>
        </Col>
      </Row>
      {/* success message */}
      <div className="position-relative" aria-live="polite" aria-atomic="true">
        <div className="success-message">
          <Toast isOpen={showSuccessToast} fade={true}>
            <ToastBody className="success-message-bg">
              <h5 className="m-0 mb-2">Success</h5>
              <p className="m-0">Status Added Successfully</p>
            </ToastBody>
          </Toast>
        </div>
      </div>
      {/* Edit Message */}
      <div className="position-relative" aria-live="polite" aria-atomic="true">
        <div className="success-message">
          <Toast isOpen={showEditToast} fade={true}>
            <ToastBody className="edit-message-bg">
              <h5 className="m-0 mb-2">Edited</h5>
              <p className="m-0">Status edited Successfully</p>
            </ToastBody>
          </Toast>
        </div>
      </div>

      {/* <StatusesTable statusesList={statusesList} isloading={isloading} /> */}
      <StatusesTable
        statusesList={filteredStatuses}
        isloading={isloading}
        toggleEdit={toggleEdit}
      />
      <AddStatus
        setShowSuccessToast={setShowSuccessToast}
        fetchAllStatuses={fetchAllStatuses}
        isOpen={modalAddNewStatus}
        toggle={toggleAdd}
      />
      <EditStatus
        setShowEditToast={setShowEditToast}
        fetchAllStatuses={fetchAllStatuses}
        status={editStatus}
        isOpen={modalEditStatus}
        toggle={toggleEdit}
      />
    </div>
  )
}
