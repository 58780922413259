import React, { useEffect, useState } from "react"
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  Button,
  CardHeader,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import axios from "axios"
import configs from "config"
const { Url, apiVersion } = configs.client
import { useSelector } from "react-redux"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import Select from "react-select"
import CustomSpinner from "components/Common/CustomSpinner"
import AddTicketClientInfo from "./AddTicketClientInfo"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function AddTicket() {
  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [forms, setForms] = useState([])
  const [selectedForm, setSelectedForm] = useState(null)
  const [errors, setErrors] = useState({})
  const [natureOfRequest, setNatureOfRequest] = useState("")
  const [complaintReport, setComplaintReport] = useState(false)
  const [statuses, setStatuses] = useState([])
  const [categories, setCategories] = useState([])
  const [teams, setTeams] = useState([])
  const [users, setUsers] = useState([])
  const [selectedStatusId, setSelectedStatusId] = useState("")
  const [selectedCategoryId, setSelectedCategoryId] = useState("")
  const [selectedTeamId, setSelectedTeamId] = useState("")
  const [selectedUserId, setSelectedUserId] = useState("")
  const [loadingData, setLoadingData] = useState(true)
  const [creatingTicket, setCreatingTicket] = useState(false)
  const [gettingFormData, setGettingFormData] = useState(false)
  const [refNo, setRefNo] = useState("")
  const [selectedTypeOfRequest, setSelectedTypeOfRequest] = useState("")

  const [selectedPriority, setSelectedPriority] = useState("")

  const [noData, setNoData] = useState(false)

  const [clientName, setClientName] = useState("")
  const [clientEmail, setClientEmail] = useState("")
  const [clientPhone, setClientPhone] = useState("")

  const fetchFormsData = () => {
    axios
      .get(`${Url}/${apiVersion}/forms`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const activeForms = res.data.data.forms.filter(
          form => form.status === "active"
        )
        setForms(activeForms)

        setLoadingData(false)
      })
      .catch(err => {
        handleError(err, "Failed to fetch forms data.")

        setLoadingData(false)
      })
  }

  const fetchFormDetails = formId => {
    setGettingFormData(true)

    axios
      .get(`${Url}/${apiVersion}/forms/${formId}`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setSelectedForm(res.data.data.form)
        setGettingFormData(false)
      })
      .catch(err => {
        setGettingFormData(false)

        handleError(err, "Failed to fetch form details.")
      })
  }

  const fetchTicketStatuses = () => {
    axios
      .get(`${Url}/${apiVersion}/ticketStatuses?status=active`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setStatuses(res.data.data.statuses)
      })
      .catch(err => {
        handleError(err, "Failed to fetch ticket statuses.")
      })
  }

  const fetchTicketCategories = () => {
    axios
      .get(`${Url}/${apiVersion}/ticketCategories?status=active`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setCategories(res.data.data.categories)
      })
      .catch(err => {
        handleError(err, "Failed to fetch ticket categories.")
      })
  }

  const fetchTeams = () => {
    axios
      .get(`${Url}/${apiVersion}/teams`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setTeams(res.data.data.teams)
      })
      .catch(err => {
        handleError(err, "Failed to fetch teams.")
      })
  }

  const fetchUsers = () => {
    axios
      .get(
        `${Url}/${apiVersion}/users?type=ticketCreate&teamID=${selectedTeamId}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      )
      .then(res => {
        const users = res.data.data.users
        setUsers(users)

        // Set the default assignee: prioritize the supervisor if available, otherwise the first user
        // if (users.length > 0) {
        //   const defaultUser = users.find(user => user.supervisor) || users[0]
        //   setSelectedUserId(defaultUser._id)
        // }
      })
      .catch(err => {
        handleError(err, "Failed to fetch users.")
      })
  }

  const createTicketRequest = () => {
    setCreatingTicket(true)

    const body = {
      priority: selectedPriority,
      status: selectedStatusId,
      category: selectedCategoryId,
      refNo: document.getElementById("refNo").value,
      requestNature: natureOfRequest,
      complaintReport: complaintReport,
      requestType: selectedTypeOfRequest,
      team: selectedTeamId,
      assignee: selectedUserId || null,
      client: {
        name: document.getElementById("clientName").value,
        email: document.getElementById("clientEmail").value,
        number: document.getElementById("clientPhone").value,
      },
      form: selectedForm._id,
      questions: selectedForm.fields.map(({ field }) => {
        let answer = null

        if (field.type.value === "multi-select") {
          answer = Array.from(
            document.querySelectorAll(`[name="${field._id}"]`)
          )
            .map(option => option.value)
            .filter(Boolean)
        } else if (field.type.value === "checkbox") {
          answer = [document.getElementById(field._id).checked]
        } else {
          answer = [document.getElementById(field._id).value]
        }

        return {
          field: field._id,
          answer,
        }
      }),
    }

    axios
      .post(`${Url}/${apiVersion}/tickets`, body, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        setCreatingTicket(false)
        toast.success("Ticket created successfully!")

        navigate("/ticket/list")
      })
      .catch(err => {
        setCreatingTicket(false)

        handleError(err, "Error creating ticket.")
      })
  }

  const handleError = (err, defaultMessage) => {
    if (
      err.response?.status === 401 ||
      err.response?.status === 0 ||
      !err.response.status
    ) {
      dispatch(unAuthUser())
      toast.error("Unauthorized. Please log in again.")
    } else {
      toast.error(defaultMessage || "An error occurred. Please try again.")
    }
  }

  useEffect(() => {
    fetchFormsData()
    fetchTicketStatuses()
    fetchTicketCategories()
    fetchTeams()
  }, [])

  useEffect(() => {
    if (selectedTeamId) {
      fetchUsersForTeam()
    }
  }, [selectedTeamId])

  useEffect(() => {
    if (selectedTeamId && selectedTypeOfRequest) {
      setDefaultUserBasedOnRequest()
    }
  }, [selectedTeamId, selectedTypeOfRequest])

  const fetchUsersForTeam = async () => {
    try {
      const res = await axios.get(
        `${Url}/${apiVersion}/users?type=ticketCreate&teamID=${selectedTeamId}`,
        {
          headers: {
            Authorization: `Bearer ${access}`,
          },
        }
      )

      const users = res.data.data.users
      setUsers(users)

      // Now set the default user based on the selected type of request
      const normalizedSelectedTypeOfRequest = selectedTypeOfRequest
        .trim()
        .toLowerCase()

      const matchingUser = users.find(user =>
        user.ticketRequests.some(
          request =>
            typeof request === "string" &&
            request.trim().toLowerCase() === normalizedSelectedTypeOfRequest
        )
      )

      if (matchingUser) {
        setSelectedUserId(matchingUser._id) // Automatically select the matching user
      } else {
        setSelectedUserId("") // Clear the selection if no match
      }
    } catch (err) {
      console.error("Error fetching users:", err)
      setUsers([]) // Clear users if fetching fails
      setSelectedUserId("") // Clear user selection
    }
  }

  const setDefaultUserBasedOnRequest = users => {
    const normalizedSelectedTypeOfRequest = selectedTypeOfRequest
      .trim()
      .toLowerCase()

    const matchingUser = users?.find(user =>
      user.ticketRequests.some(
        request =>
          typeof request === "string" &&
          request.trim().toLowerCase() === normalizedSelectedTypeOfRequest
      )
    )

    if (matchingUser) {
      setSelectedUserId(matchingUser._id) // Automatically select the matching user
    } else {
      setSelectedUserId("") // Clear the selection if no match
    }
  }

  const handleFormChange = selectedOption => {
    const selectedFormId = selectedOption ? selectedOption.value : null
    if (selectedFormId) {
      setErrors(prevErrors => ({ ...prevErrors, form: null }))
      fetchFormDetails(selectedFormId)
    } else {
      setSelectedForm(null) // If no form selected, clear selectedForm
    }
  }

  const handleStatusChange = e => {
    const selectedStatusId = e.target.value
    setSelectedStatusId(selectedStatusId)
    setErrors(prevErrors => ({ ...prevErrors, status: null }))
  }

  const handleCategoryChange = e => {
    const selectedCategoryId = e.target.value
    setSelectedCategoryId(selectedCategoryId)
    setErrors(prevErrors => ({ ...prevErrors, category: null }))
  }

  const handleTeamChange = e => {
    const selectedTeamId = e.target.value
    setSelectedTeamId(selectedTeamId)
    setErrors(prevErrors => ({ ...prevErrors, team: null }))
  }

  const handleUserChange = e => {
    const selectedUserId = e.target.value
    setSelectedUserId(selectedUserId)
  }

  const handleInputChange = async e => {
    const { id, value } = e.target

    if (id === "refNo") {
      setRefNo(value)
    }

    // if (id === "typeOfRequest") {
    //   // Find the first team that has the selected type of request in its ticketRequests array
    //   const matchingTeam = teams.find(team =>
    //     team.ticketRequests.includes(value)
    //   )

    //   if (matchingTeam) {
    //     setSelectedTeamId(matchingTeam._id) // Automatically select the matching team
    //     fetchUsers(matchingTeam._id) // Fetch users for the selected team
    //   }
    // }

    if (id === "typeOfRequest") {
      setSelectedTypeOfRequest(value)

      // Find the team that handles this type of request
      const matchingTeam = teams.find(team =>
        team.ticketRequests.includes(value)
      )

      if (matchingTeam) {
        setSelectedTeamId(matchingTeam._id) // Automatically select the matching team

        // Fetch users for the selected team
        try {
          const res = await axios.get(
            `${Url}/${apiVersion}/users?type=ticketCreate&teamID=${matchingTeam._id}`,
            {
              headers: {
                Authorization: `Bearer ${access}`,
              },
            }
          )
          const users = res.data.data.users
          setUsers(users)

          // After fetching the users, find the user that has the matching ticketRequest
          const matchingUser = users.find(user =>
            user.ticketRequests.includes(value)
          )

          if (matchingUser) {
            setSelectedUserId(matchingUser._id) // Automatically select the matching user
          } else {
            setSelectedUserId("") // Clear selection if no matching user
          }
        } catch (err) {
          console.error("Error fetching users:", err)
        }
      } else {
        setSelectedTeamId("") // Clear team selection if no matching team
        setUsers([]) // Clear users if no matching team
        setSelectedUserId("") // Clear user selection if no matching team
      }
    }

    // If the priority is selected, remove the error for priority
    if (id === "prioritySelect" && value) {
      setErrors(prevErrors => {
        const newErrors = { ...prevErrors }
        delete newErrors.priority
        return newErrors
      })
    }

    setErrors(prevErrors => ({
      ...prevErrors,
      [id]: value ? null : errors[id],
    }))
  }

  const handleMultiSelectChange = (field, selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map(option => option.value)
      : []

    setErrors(prevErrors => {
      const newErrors = { ...prevErrors }

      if (selectedValues.length > 0) {
        delete newErrors[field._id]
      } else {
        if (field.required) {
          newErrors[field._id] = `Required`
        }
      }

      return newErrors
    })

    setSelectedForm(prevForm => {
      if (!prevForm) return prevForm

      return {
        ...prevForm,
        fields: prevForm.fields.map(f => {
          if (f.field._id === field._id) {
            return { ...f, selectedValues }
          }
          return f
        }),
      }
    })
  }

  const validateField = (field, value) => {
    let isValid = true

    if (field.type.value === "multi-select") {
      if (field.required && (!value || value.length === 0)) {
        setErrors(prevErrors => ({
          ...prevErrors,
          [field._id]: `Required`,
        }))
        isValid = false
      } else {
        setErrors(prevErrors => {
          const newErrors = { ...prevErrors }
          delete newErrors[field._id]
          return newErrors
        })
        isValid = true
      }
    } else if (field.type.value === "checkbox") {
      if (field.required && value === null) {
        setErrors(prevErrors => ({
          ...prevErrors,
          [field._id]: `${field.name} must be checked or unchecked`,
        }))
        isValid = false
      } else {
        setErrors(prevErrors => {
          const newErrors = { ...prevErrors }
          delete newErrors[field._id]
          return newErrors
        })
        isValid = true
      }
    } else {
      if (field.required && (!value || value.length === 0)) {
        setErrors(prevErrors => ({
          ...prevErrors,
          [field._id]: `Required`,
        }))
        isValid = false
      } else {
        setErrors(prevErrors => {
          const newErrors = { ...prevErrors }
          delete newErrors[field._id]
          return newErrors
        })
        isValid = true
      }
    }

    return isValid
  }

  const renderField = field => {
    const error = errors[field._id]

    switch (field.type.value) {
      case "dropdown":
        return (
          <FormGroup key={field._id}>
            <Label for={field._id}>
              {field.name}{" "}
              {field.required && <span className="text-danger">*</span>}
            </Label>
            <Input
              type="select"
              id={field._id}
              defaultValue={field.defaultValue}
              onBlur={e => validateField(field, e.target.value)}
              onChange={handleInputChange}
            >
              <option value="">Select {field.name}</option>
              {field.values.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </Input>
            {error && <span className="text-danger">{error}</span>}
          </FormGroup>
        )
      case "text":
        return (
          <FormGroup key={field._id}>
            <Label for={field._id}>
              {field.name}{" "}
              {field.required && <span className="text-danger">*</span>}
            </Label>
            <Input
              type="text"
              id={field._id}
              placeholder={field.name}
              onBlur={e => validateField(field, e.target.value)}
              onChange={handleInputChange}
            />
            {error && <span className="text-danger">{error}</span>}
          </FormGroup>
        )
      case "number":
        return (
          <FormGroup key={field._id}>
            <Label for={field._id}>
              {field.name}{" "}
              {field.required && <span className="text-danger">*</span>}
            </Label>
            <Input
              type="text"
              id={field._id}
              placeholder={field.name}
              pattern="[0-9]*"
              onBlur={e => validateField(field, e.target.value)}
              onInput={e => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "")
              }}
              onChange={handleInputChange}
            />
            {error && <span className="text-danger">{error}</span>}
          </FormGroup>
        )
      case "text-area":
        return (
          <FormGroup key={field._id}>
            <Label for={field._id}>
              {field.name}{" "}
              {field.required && <span className="text-danger">*</span>}
            </Label>
            <Input
              type="textarea"
              id={field._id}
              placeholder={field.name}
              onBlur={e => validateField(field, e.target.value)}
              onChange={handleInputChange}
            />
            {error && <span className="text-danger">{error}</span>}
          </FormGroup>
        )
      case "checkbox":
        return (
          <FormGroup key={field._id} check inline className="mb-2">
            <Label check>
              <Input
                type="checkbox"
                id={field._id}
                onChange={e => validateField(field, e.target.checked)}
                onBlur={e => validateField(field, e.target.checked)}
              />
              {field.name}{" "}
              {field.required && <span className="text-danger">*</span>}
            </Label>
            {error && <span className="text-danger">{error}</span>}
          </FormGroup>
        )
      case "multi-select":
        return (
          <FormGroup key={field._id}>
            <Label for={field._id}>
              {field.name}{" "}
              {field.required && <span className="text-danger">*</span>}
            </Label>
            <Select
              isClearable
              isMulti
              name={field._id}
              options={field.values.map(value => ({ value, label: value }))}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={selectedOptions => {
                handleMultiSelectChange(field, selectedOptions)
              }}
              onBlur={() => {
                const fieldValue = document.getElementsByName(field._id)[0]
                  .value
                validateField(field, fieldValue)
              }}
              // defaultValue={field.values
              //   .filter(value => value === field.defaultValue)
              //   .map(value => ({ value, label: value }))}
            />
            {error && <span className="text-danger">{error}</span>}
          </FormGroup>
        )
      case "decimal":
        return (
          <FormGroup key={field._id}>
            <Label for={field._id}>
              {field.name}{" "}
              {field.required && <span className="text-danger">*</span>}
            </Label>
            <Input
              type="text"
              id={field._id}
              placeholder={field.name}
              onBlur={e => validateField(field, e.target.value)}
              onInput={e => {
                const value = e.target.value
                e.target.value = value.match(/^\d*\.?\d{0,4}$/)
                  ? value
                  : value.slice(0, -1)
              }}
              onChange={handleInputChange}
            />
            {error && <span className="text-danger">{error}</span>}
          </FormGroup>
        )
      case "date":
        return (
          <FormGroup key={field._id}>
            <Label for={field._id}>
              {field.name}{" "}
              {field.required && <span className="text-danger">*</span>}
            </Label>
            <Input
              type="date"
              id={field._id}
              onBlur={e => validateField(field, e.target.value)}
              onChange={handleInputChange}
            />
            {error && <span className="text-danger">{error}</span>}
          </FormGroup>
        )
      default:
        return null
    }
  }

  const validateForm = () => {
    let isValid = true
    const newErrors = {}

    if (!selectedForm) {
      newErrors.form = "Required"
      isValid = false
    } else if (selectedForm.fields) {
      selectedForm.fields.forEach(({ field, selectedValues }) => {
        const fieldValue = document.getElementById(field._id)?.value
        const fieldType = field.type.value

        if (fieldType === "multi-select") {
          // console.log("selected values are", selectedValues)
          if (
            field.required &&
            (!selectedValues || selectedValues.length === 0)
          ) {
            newErrors[field._id] = `Required`
            isValid = false
          }
        } else if (field.required && !fieldValue) {
          newErrors[field._id] = `Required`
          isValid = false
        }
      })
    }

    if (!selectedPriority) {
      newErrors.priority = "Required"
      isValid = false
    }

    if (!selectedStatusId) {
      newErrors.status = "Required"
      isValid = false
    }

    if (!selectedCategoryId) {
      newErrors.category = "Required"
      isValid = false
    }

    if (!document.getElementById("refNo").value) {
      newErrors.refNo = "Required"
      isValid = false
    }

    if (!selectedTeamId) {
      newErrors.team = "Required"
      isValid = false
    }

    if (!document.getElementById("clientName").value) {
      newErrors.clientName = "Required"
      isValid = false
    }

    if (!document.getElementById("clientPhone").value) {
      newErrors.clientPhone = "Required"
      isValid = false
    }

    if (!document.getElementById("clientEmail").value) {
      newErrors.clientEmail = "Required"
      isValid = false
    }

    if (!selectedTypeOfRequest) {
      newErrors.typeOfRequest = "Required"
      isValid = false
    }

    if (!natureOfRequest) {
      newErrors.natureOfRequest = "Required"
      isValid = false
    }

    setErrors(newErrors)
    return isValid
  }

  const handleNatureOfRequestChange = e => {
    const value = e.target.value
    setNatureOfRequest(value)

    if (value) {
      setErrors(prevErrors => {
        const newErrors = { ...prevErrors }
        delete newErrors.natureOfRequest
        return newErrors
      })
    }
  }

  const handleSave = () => {
    if (validateForm()) {
      createTicketRequest()
    } else {
      console.log("Errors:", errors)
    }
  }

  useEffect(() => {
    if (refNo) {
      axios
        .get(
          `https://rd0.cpvarabia.com/api/Care/Project_Users.php?Token=OKRJ_R85rkn9nrgg&ReferenceNo=${refNo}`
        )
        .then(res => {
          if (res.data.error) {
            setNoData(true)
            // Clear the client info fields if there's an error
            setClientName("")
            setClientEmail("")
            setClientPhone("")
          } else {
            setNoData(false)
            // Set the client info fields with the response data
            setClientName(res.data.Name)
            setClientEmail(res.data.Email)
            setClientPhone(res.data.Mobile)
          }
        })
        .catch(err => {
          console.log(err)
        })
    }
  }, [refNo])

  // Here we replace native select with react-select
  const customSelectStyles = {
    control: provided => ({
      ...provided,
      borderRadius: "0.25rem",
      border: "1px solid #ced4da",
    }),
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <div className="py-3 px-2">
      <Container fluid className="py-4 px-5 pt-2 position-relative">
        <div className="d-flex align-items-center mb-3">
          <i
            onClick={() => navigate("/ticket/list")}
            className="mdi mdi-arrow-left text-secondary py-0 px-3"
            style={{ cursor: "pointer" }}
          ></i>

          <div className="flex-grow-1">
            <Breadcrumbs title="Tickets" breadcrumbItem="New Ticket" />
          </div>
        </div>

        {creatingTicket && (
          <div className="sending-template-overlay-loading d-flex justify-content-center align-items-center">
            <div>
              <CustomSpinner />
            </div>
          </div>
        )}

        {loadingData ? (
          <Row>
            <Col md={4} className="mx-auto">
              <CustomSpinner />
            </Col>
          </Row>
        ) : (
          <Row className="gx-4">
            <Col md={8} style={{ height: "83vh" }}>
              <Card style={{ height: "100%", overflow: "auto" }}>
                <CardHeader>
                  <h5 className="mb-0">Form</h5>
                  {selectedForm && (
                    <span>
                      Fields marked with <span className="text-danger">*</span>{" "}
                      are required
                    </span>
                  )}
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="formSelect">Form Name</Label>
                    <Select
                      id="formSelect"
                      isClearable
                      options={forms.map(form => ({
                        value: form._id,
                        label: form.name,
                      }))}
                      onChange={handleFormChange}
                      styles={customSelectStyles}
                    />
                    {errors.form && (
                      <div className="text-danger mb-3">{errors.form}</div>
                    )}
                  </FormGroup>

                  {!selectedForm ? (
                    <div className="text-center mt-5">
                      <i
                        className="mdi mdi-file-document-outline"
                        style={{ fontSize: "50px", color: "#ccc" }}
                      ></i>
                      <p className="mt-3" style={{ color: "#ccc" }}>
                        No Form Chosen Yet!
                      </p>
                    </div>
                  ) : gettingFormData ? (
                    <CustomSpinner />
                  ) : (
                    <div className="mt-3">
                      {selectedForm.fields && selectedForm.fields.length > 0 ? (
                        selectedForm.fields.map(field =>
                          renderField(field.field)
                        )
                      ) : (
                        <p>No fields available for this form.</p>
                      )}
                    </div>
                  )}
                </CardBody>
              </Card>
            </Col>

            <Col md={4} style={{ height: "78vh" }}>
              <Card style={{ height: "100%", overflow: "auto" }}>
                <CardBody>
                  <div className="p-2 rounded-2 border shadow-sm">
                    <h5>Ticket Info</h5>

                    {/* priority select  */}
                    <FormGroup>
                      <Label for="prioritySelect">
                        Priority <span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="prioritySelect"
                        isClearable
                        options={[
                          { value: "Low", label: "Low" },
                          { value: "Normal", label: "Normal" },
                          { value: "High", label: "High" },
                          { value: "Urgent", label: "Urgent" },
                        ]}
                        onChange={selectedOption => {
                          setErrors(prevErrors => ({
                            ...prevErrors,
                            priority: null,
                          }))
                          setSelectedPriority(
                            selectedOption ? selectedOption.value : ""
                          )
                        }}
                        styles={customSelectStyles}
                      />
                      {errors.priority && (
                        <span className="text-danger">{errors.priority}</span>
                      )}
                    </FormGroup>

                    {/* select category  */}
                    <FormGroup>
                      <Label for="categorySelect">
                        Category <span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="categorySelect"
                        isClearable
                        options={categories.map(category => ({
                          value: category._id,
                          label: category.name,
                        }))}
                        onChange={selectedOption => {
                          setErrors(prevErrors => ({
                            ...prevErrors,
                            category: null,
                          }))
                          setSelectedCategoryId(
                            selectedOption ? selectedOption.value : ""
                          )
                        }}
                        styles={customSelectStyles}
                      />
                      {errors.category && (
                        <span className="text-danger">{errors.category}</span>
                      )}
                    </FormGroup>

                    {/* select status  */}
                    <FormGroup>
                      <Label for="statusSelect">
                        Status <span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="statusSelect"
                        isClearable
                        options={statuses
                          .filter(status => status.category !== "solved")
                          .map(status => ({
                            value: status._id,
                            label: status.name,
                          }))}
                        onChange={selectedOption => {
                          setErrors(prevErrors => ({
                            ...prevErrors,
                            status: null,
                          }))
                          setSelectedStatusId(
                            selectedOption ? selectedOption.value : ""
                          )
                        }}
                        styles={customSelectStyles}
                      />
                      {errors.status && (
                        <span className="text-danger">{errors.status}</span>
                      )}
                    </FormGroup>

                    {/* ref number  */}
                    <FormGroup>
                      <Label for="refNo">
                        Reference number <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        id="refNo"
                        placeholder="Enter ref Number"
                        onChange={handleInputChange}
                      />
                      {errors.refNo && (
                        <span className="text-danger">{errors.refNo}</span>
                      )}
                    </FormGroup>

                    {/* type of request  */}
                    <FormGroup>
                      <Label for="typeOfRequest">
                        Type of Request <span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="typeOfRequest"
                        isClearable
                        options={[
                          { value: "RD0", label: "RD0" },
                          { value: "Edit RD0", label: "Edit RD0" },
                          { value: "Missing Data", label: "Missing Data" },
                          { value: "Design Review", label: "Design Review" },
                          { value: "RD6", label: "RD6" },
                          { value: "RD7", label: "RD7" },
                          { value: "Finance", label: "Finance" },
                          { value: "Inspection", label: "Inspection" },
                          { value: "MALATH Issue", label: "MALATH Issue" },
                          {
                            value: "MALATH Complaint",
                            label: "MALATH Complaint",
                          },
                          { value: "Other", label: "Other" },
                        ]}
                        onChange={selectedOption => {
                          setSelectedTypeOfRequest(
                            selectedOption ? selectedOption.value : ""
                          )
                          setErrors(prevErrors => ({
                            ...prevErrors,
                            typeOfRequest: null,
                          }))

                          // Automatically select the corresponding team based on the selected request
                          const matchingTeam = teams.find(team =>
                            team.ticketRequests.includes(selectedOption.value)
                          )

                          if (matchingTeam) {
                            setSelectedTeamId(matchingTeam._id)
                          } else {
                            setSelectedTeamId("") // Clear team selection if no matching team
                          }
                        }}
                        styles={customSelectStyles}
                      />
                      {errors.typeOfRequest && (
                        <span className="text-danger">
                          {errors.typeOfRequest}
                        </span>
                      )}
                    </FormGroup>

                    {/* nature of request  */}
                    <FormGroup>
                      <Label for="natureOfRequest">
                        Nature of Request <span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="natureOfRequest"
                        isClearable
                        options={[
                          { value: "Request", label: "Request" },
                          { value: "Complaint", label: "Complaint" },
                          { value: "Inquiry", label: "Inquiry" },
                        ]}
                        onChange={selectedOption => {
                          setErrors(prevErrors => ({
                            ...prevErrors,
                            natureOfRequest: null,
                          }))
                          setNatureOfRequest(
                            selectedOption ? selectedOption.value : ""
                          )
                        }}
                        styles={customSelectStyles}
                      />
                      {errors.natureOfRequest && (
                        <span className="text-danger">
                          {errors.natureOfRequest}
                        </span>
                      )}
                    </FormGroup>

                    {natureOfRequest === "Complaint" && (
                      <FormGroup check inline className="mb-2">
                        <Label check>
                          <Input
                            type="checkbox"
                            id="complaintReport"
                            checked={complaintReport}
                            onClick={() => setComplaintReport(!complaintReport)}
                          />{" "}
                          Complaint Report{" "}
                          <span className="text-danger">*</span>
                        </Label>
                        {errors.complaintReport && (
                          <span className="text-danger">
                            {errors.complaintReport}
                          </span>
                        )}
                      </FormGroup>
                    )}

                    {/* team select */}
                    <FormGroup>
                      <Label for="teamSelect">
                        Choose a team <span className="text-danger">*</span>
                      </Label>
                      <Select
                        id="teamSelect"
                        isClearable
                        options={teams.map(team => ({
                          value: team._id,
                          label: team.name,
                        }))}
                        onChange={selectedOption => {
                          setErrors(prevErrors => ({
                            ...prevErrors,
                            team: null,
                          }))
                          setSelectedTeamId(
                            selectedOption ? selectedOption.value : ""
                          )
                        }}
                        value={
                          teams.find(team => team._id === selectedTeamId)
                            ? {
                                value: selectedTeamId,
                                label: teams.find(
                                  team => team._id === selectedTeamId
                                ).name,
                              }
                            : null
                        }
                        styles={customSelectStyles}
                        placeholder="Select team"
                      />
                      {errors.team && (
                        <span className="text-danger">{errors.team}</span>
                      )}
                    </FormGroup>

                    {/* assignee select  */}
                    <FormGroup>
                      <Label for="assigneeSelect">Assignee</Label>
                      <Select
                        id="assigneeSelect"
                        isClearable
                        isDisabled={!selectedTeamId} // Disable when no team is selected
                        options={users.map(user => ({
                          value: user._id,
                          label: `${user.firstName} ${user.lastName}`,
                        }))}
                        onChange={selectedOption => {
                          setSelectedUserId(
                            selectedOption ? selectedOption.value : ""
                          )
                        }}
                        value={
                          users.find(user => user._id === selectedUserId)
                            ? {
                                value: selectedUserId,
                                label:
                                  users.find(
                                    user => user._id === selectedUserId
                                  ).firstName +
                                  " " +
                                  users.find(
                                    user => user._id === selectedUserId
                                  ).lastName,
                              }
                            : null
                        }
                        styles={customSelectStyles}
                        placeholder="Choose assignee"
                      />
                    </FormGroup>
                  </div>

                  <AddTicketClientInfo
                    handleInputChange={handleInputChange}
                    clientName={clientName}
                    clientPhone={clientPhone}
                    clientEmail={clientEmail}
                    errors={errors}
                  />
                </CardBody>
              </Card>

              <div className="d-flex justify-content-end">
                <Button
                  onClick={e => {
                    e.preventDefault()
                    navigate("/ticket/list")
                  }}
                  color="secondary"
                  outline
                  className="py-1 px-2"
                >
                  Cancel
                </Button>
                <Button
                  color="secondary"
                  onClick={handleSave}
                  className="py-1 px-2 ms-2"
                >
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default AddTicket
