import React, { useEffect, useState } from "react"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  Label,
  CardHeader,
} from "reactstrap"
import axios from "axios"
import configs from "config"
const { Url, apiVersion } = configs.client
import { useNavigate } from "react-router-dom"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import Breadcrumbs from "components/Common/Breadcrumb"
import { useSelector } from "react-redux"
import CustomSpinner from "components/Common/CustomSpinner"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const AddFormTicket = () => {
  const [availableFields, setAvailableFields] = useState([])
  const [filteredFields, setFilteredFields] = useState([])
  const [selectedFields, setSelectedFields] = useState([])
  const [formName, setFormName] = useState("")
  const [formDescription, setFormDescription] = useState("")
  const [searchTerm, setSearchTerm] = useState("")
  const [creatingForm, setCreatingForm] = useState(false)
  const [loadingFields, setLoadingFields] = useState(true)
  const [toggleSwitch, setToggleSwitch] = useState(false)
  const [isDefault, setIsDefault] = useState(false)

  // State for form validation errors
  const [errors, setErrors] = useState({ formName: "", selectedFields: "" })

  const { access, authorized } = useSelector(state => ({
    access: state.Login?.userAccess,
    authorized: state.Login.authorized,
  }))

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const onDragEnd = result => {
    const { source, destination } = result
    if (!destination) return
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return
    }

    let movedItem
    let newAvailableFields = Array.from(availableFields)
    let newSelectedFields = Array.from(selectedFields)

    if (
      source.droppableId === "availableFields" &&
      destination.droppableId === "selectedFields"
    ) {
      movedItem = newAvailableFields[source.index]
      newAvailableFields.splice(source.index, 1)
      newSelectedFields.splice(destination.index, 0, movedItem)
    }

    if (
      source.droppableId === "selectedFields" &&
      destination.droppableId === "availableFields"
    ) {
      movedItem = newSelectedFields[source.index]
      newSelectedFields.splice(source.index, 1)
      newAvailableFields.splice(destination.index, 0, movedItem)
    }

    if (
      source.droppableId === "selectedFields" &&
      destination.droppableId === "selectedFields"
    ) {
      movedItem = newSelectedFields[source.index]
      newSelectedFields.splice(source.index, 1)
      newSelectedFields.splice(destination.index, 0, movedItem)
    }

    setAvailableFields(newAvailableFields)
    setFilteredFields(newAvailableFields)
    setSelectedFields(newSelectedFields)
  }

  const onDeleteField = fieldId => {
    const fieldToDelete = selectedFields.find(field => field.id === fieldId)
    setSelectedFields(prev => prev.filter(field => field.id !== fieldId))
    setAvailableFields(prev => [...prev, fieldToDelete])
    setFilteredFields(prev => [...prev, fieldToDelete])
  }

  const onAddField = index => {
    const movedItem = filteredFields[index]
    setAvailableFields(prev => prev.filter(field => field.id !== movedItem.id))
    setFilteredFields(prev => prev.filter(field => field.id !== movedItem.id))
    setSelectedFields(prev => [...prev, movedItem])
  }

  const fetchFieldsData = () => {
    axios
      .get(`${Url}/${apiVersion}/fields?status=active`, {
        headers: {
          Authorization: `Bearer ${access}`,
        },
      })
      .then(res => {
        const fetchedFields = res.data.data.fields.map(field => ({
          id: field._id,
          content: field.name,
          type: field.type,
        }))

        setAvailableFields(fetchedFields)
        setFilteredFields(fetchedFields)
        setLoadingFields(false)
      })
      .catch(err => {
        setLoadingFields(false)

        if (
          err.response.status == 401 ||
          err.response.status == 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Failed to get fields. Please try again later.")
        }
      })
  }

  const validateForm = () => {
    const newErrors = {}
    if (!formName) {
      newErrors.formName = "Required"
    }
    if (selectedFields.length === 0) {
      newErrors.selectedFields = "Please select at least one field."
    }
    setErrors(newErrors)
    return Object.keys(newErrors).length === 0
  }

  const submitFormCreation = () => {
    if (!validateForm()) {
      return
    }

    const dataToSend = {
      name: formName,
      description: formDescription,
      fields: selectedFields.map(field => field.id),
      default: isDefault,
    }

    setCreatingForm(true)

    axios
      .post(`${Url}/${apiVersion}/forms`, dataToSend, {
        headers: {
          Authorization: `Bearer ${access}`,
          "Content-Type": "application/json; charset=utf8",
        },
      })
      .then(res => {
        setCreatingForm(false)
        navigate("/ticket/forms")
      })
      .catch(err => {
        setCreatingForm(false)

        if (
          err.response.status == 401 ||
          err.response.status == 0 ||
          !err.response.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
          toast.error("Failed to create form. Please try again later.")
        }
      })
  }

  useEffect(() => {
    let filtered = availableFields
    if (searchTerm !== "") {
      filtered = filtered.filter(field =>
        field.content.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }

    filtered = filtered.filter(
      field => !selectedFields.some(selected => selected.id === field.id)
    )

    setFilteredFields(filtered)
  }, [searchTerm, availableFields, selectedFields])

  useEffect(() => {
    fetchFieldsData()
  }, [])

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <DragDropForm
        availableFields={filteredFields}
        selectedFields={selectedFields}
        setAvailableFields={setAvailableFields}
        setSelectedFields={setSelectedFields}
        onDeleteField={onDeleteField}
        onAddField={onAddField}
        formName={formName}
        setFormName={setFormName}
        formDescription={formDescription}
        setFormDescription={setFormDescription}
        submitFormCreation={submitFormCreation}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        creatingForm={creatingForm}
        loadingFields={loadingFields}
        errors={errors}
        toggleSwitch={toggleSwitch}
        setToggleSwitch={setToggleSwitch}
        isDefault={isDefault}
        setIsDefault={setIsDefault}
      />
    </DragDropContext>
  )
}

const DragDropForm = ({
  availableFields,
  selectedFields,
  onDeleteField,
  onAddField,
  formName,
  setFormName,
  formDescription,
  setFormDescription,
  submitFormCreation,
  searchTerm,
  setSearchTerm,
  creatingForm,
  loadingFields,
  errors,
  toggleSwitch,
  setToggleSwitch,
  isDefault,
  setIsDefault,
}) => {
  const navigate = useNavigate()

  return (
    <div className="py-3 px-2">
      <Container fluid className="py-4 px-5 pt-2 position-relative">
        <div className="d-flex align-items-center mb-3">
          <i
            onClick={() => navigate("/ticket/forms")}
            className="mdi mdi-arrow-left text-secondary py-0 px-3 me-2"
            style={{ cursor: "pointer" }}
          ></i>

          <div className="flex-grow-1">
            <Breadcrumbs title="Forms" breadcrumbItem="New Form" />
          </div>
        </div>

        {creatingForm && (
          <div className="sending-template-overlay-loading d-flex justify-content-center align-items-center">
            <div>
              <CustomSpinner />
            </div>
          </div>
        )}

        {loadingFields ? (
          <Row>
            <Col md={4} className="mx-auto">
              <CustomSpinner />
            </Col>
          </Row>
        ) : (
          <Row className="gx-4 mt-2">
            <Col md={4}>
              <Card className="bg-white rounded-top-3 overflow-hidden">
                <CardHeader>
                  <h5 className="mb-0">Add Fields</h5>
                  <Input
                    type="search"
                    placeholder="Search fields..."
                    value={searchTerm}
                    onChange={e => setSearchTerm(e.target.value)}
                    className={`mt-2 bg-light rounded-3 border-0 ${
                      errors.searchTerm ? "is-invalid" : ""
                    }`}
                  />
                  {errors.searchTerm && (
                    <div className="invalid-feedback">{errors.searchTerm}</div>
                  )}
                </CardHeader>
                <CardBody
                  className="bg-light"
                  style={{
                    maxHeight: "calc(2px + 74vh)",
                    overflowY: "auto",
                  }}
                >
                  <Droppable droppableId="availableFields">
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{ minHeight: "400px" }}
                      >
                        {availableFields.map((field, index) => (
                          <Draggable
                            key={field.id}
                            draggableId={field.id}
                            index={index}
                          >
                            {provided => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="mb-2"
                              >
                                <Card className="p-2 d-flex flex-row justify-content-between">
                                  <span className="d-flex align-items-center">
                                    {field.type?.name === "Dropdown" && (
                                      <i className="mdi mdi-form-dropdown fa-lg"></i>
                                    )}
                                    {field.type?.name === "Checkbox" && (
                                      <i className="mdi mdi-checkbox-marked-outline fa-lg"></i>
                                    )}
                                    {field.type?.name === "Multi-line" && (
                                      <i className="mdi mdi-format-align-left fa-lg"></i>
                                    )}
                                    {field.type?.name === "Multi-select" && (
                                      <i className="bx bx-select-multiple fa-lg"></i>
                                    )}
                                    {field.type?.name === "Text" && (
                                      <i className="mdi mdi-format-text fa-lg"></i>
                                    )}
                                    {field.type?.name === "Number" && (
                                      <i className="bx bx-hash fa-lg"></i>
                                    )}
                                    {field.type?.name === "Date" && (
                                      <i className="mdi mdi-calendar-blank-outline fa-lg"></i>
                                    )}
                                    {field.type?.name === "Decimal" && (
                                      <i className="mdi mdi-decimal fa-lg"></i>
                                    )}

                                    <h5 className="ms-2 mb-0">
                                      {field.content.length > 20
                                        ? `${field.content.substring(0, 20)}...`
                                        : field.content}
                                    </h5>
                                  </span>

                                  <i
                                    className="mdi mdi-plus fa-lg"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => onAddField(index)}
                                  ></i>
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </CardBody>
              </Card>
            </Col>

            <Col md={8}>
              <Card className="rounded-3 overflow-hidden">
                <CardHeader>
                  <h5>New Form</h5>
                  <p className="mb-0">
                    This field enables people to choose one option in a menu of
                    choices.
                  </p>
                </CardHeader>
                <CardBody>
                  <FormGroup>
                    <Label for="formName">Name</Label>
                    <Input
                      id="formName"
                      name="name"
                      placeholder="Enter form name"
                      value={formName}
                      onChange={e => setFormName(e.target.value)}
                      className={errors.formName ? "is-invalid" : ""}
                    />
                    {errors.formName && (
                      <div className="invalid-feedback">{errors.formName}</div>
                    )}
                  </FormGroup>

                  <FormGroup>
                    <Label for="formDescription">Description</Label>
                    <Input
                      id="formDescription"
                      name="description"
                      type="textarea"
                      placeholder="Enter form description"
                      rows="3"
                      value={formDescription}
                      onChange={e => setFormDescription(e.target.value)}
                    />
                  </FormGroup>

                  <FormGroup check inline className="mb-3">
                    <Input
                      type="checkbox"
                      id="isDefault"
                      checked={isDefault}
                      onClick={() => setIsDefault(!isDefault)}
                    />
                    <Label for="isDefault" check>
                      Make this form the default form
                    </Label>
                  </FormGroup>

                  <Droppable droppableId="selectedFields">
                    {provided => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        style={{
                          minHeight: "calc(-7px + 42vh )",
                          padding: "10px",
                          maxHeight: "calc(-7px + 42vh )",
                          overflow: "auto",
                          border: errors.selectedFields
                            ? "1px solid #f00"
                            : "none",
                          borderRadius: "8px",
                        }}
                        className={errors.selectedFields ? "is-invalid" : ""}
                      >
                        {selectedFields.length === 0 && (
                          <div
                            style={{
                              color: errors.selectedFields ? "#f00" : "#888",
                              textAlign: "center",
                            }}
                          >
                            {errors.selectedFields
                              ? errors.selectedFields
                              : "Drag and drop fields here to build the form."}
                          </div>
                        )}

                        {selectedFields.map((field, index) => (
                          <Draggable
                            key={field.id}
                            draggableId={field.id}
                            index={index}
                          >
                            {provided => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className="mb-2"
                              >
                                <Card className="p-2 d-flex flex-row justify-content-between align-items-center bg-light">
                                  <span className="d-flex align-items-center">
                                    {field.type?.name === "Dropdown" && (
                                      <i className="mdi mdi-form-dropdown fa-lg"></i>
                                    )}
                                    {field.type?.name === "Checkbox" && (
                                      <i className="mdi mdi-checkbox-marked-outline fa-lg"></i>
                                    )}
                                    {field.type?.name === "Multi-line" && (
                                      <i className="mdi mdi-format-align-left fa-lg"></i>
                                    )}
                                    {field.type?.name === "Multi-select" && (
                                      <i className="bx bx-select-multiple fa-lg"></i>
                                    )}
                                    {field.type?.name === "Text" && (
                                      <i className="mdi mdi-format-text fa-lg"></i>
                                    )}
                                    {field.type?.name === "Number" && (
                                      <i className="bx bx-hash fa-lg"></i>
                                    )}
                                    {field.type?.name === "Date" && (
                                      <i className="mdi mdi-calendar-blank-outline fa-lg"></i>
                                    )}
                                    {field.type?.name === "Decimal" && (
                                      <i className="mdi mdi-decimal fa-lg"></i>
                                    )}

                                    <h5 className="ms-2 mb-0">
                                      {field.content.length > 20
                                        ? `${field.content.substring(0, 20)}...`
                                        : field.content}
                                    </h5>
                                  </span>

                                  <Button
                                    className="p-2 px-3"
                                    close
                                    onClick={() => onDeleteField(field.id)}
                                  />
                                </Card>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>

                  <div className="mt-3 d-flex justify-content-end">
                    <Button
                      onClick={e => {
                        e.preventDefault()
                        navigate("/ticket/forms")
                      }}
                      color="secondary"
                      outline
                      className="py-1 px-2"
                    >
                      Cancel
                    </Button>
                    <Button
                      color="secondary"
                      className="py-1 px-2 ms-2"
                      onClick={submitFormCreation}
                    >
                      Save
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  )
}

export default AddFormTicket
