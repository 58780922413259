import OfflineIcon from "components/Common/Icons/SidebarIcons/OfflineIcon"
import OnlineIcon from "components/Common/Icons/SidebarIcons/OnlineIcon"
import ServiceHoursIcon from "components/Common/Icons/SidebarIcons/ServiceHoursIcon"
import React from "react"

export default function TicketPerformanceTable({
  isloading,
  TicketPerformanceData,
}) {
  return (
    <div className="table-container table-performance  m-0 p-0">
      {isloading ? (
        <div className="  loading">
          <div className="spinner-border "></div>
        </div>
      ) : (
        <table className="">
          <thead className="text-center">
            <tr>
              <th rowSpan="2" className="border-end">
                User
              </th>
              <th className="border-end" colSpan="3">
                Ticket
              </th>
              {/* <th rowSpan='2'>Total Tickets </th>
                            <th rowSpan='2' >Solved Tickets </th>
                            <th rowSpan='2' > UnSolved Tickets </th> */}
              <th className="border-end" rowSpan="2">
                Avg Solving Time{" "}
              </th>
              <th className="border-end text-center" colSpan="4">
                Feedback
              </th>
            </tr>

            <tr>
              {/* ************************ticket************************ */}
              <th className="border-end">Total</th>
              <th className="border-end">Solved</th>
              <th className="border-end">Unsolved</th>

              {/* ************************feedback************************ */}
              <th className="border-end text-center">Total</th>
              <th className="border-end text-center ">
                <div className="d-flex justify-content-center">
                  <button
                    disabled
                    className="feedback-style positive-feedback d-flex align-items-center  gap-1"
                  >
                    <OnlineIcon />
                    <span className="m-0">Positive </span>
                  </button>
                </div>
              </th>
              <th className="border-end ">
                <div className="d-flex justify-content-center">
                  <button
                    disabled
                    className="feedback-style negative-feedback d-flex align-items-center d-flex justify-content-center  gap-1"
                  >
                    <OfflineIcon />
                    <span> Negative</span>
                  </button>
                </div>
              </th>

              <th className="border-end text-center">
                <div className="d-flex justify-content-center">
                  <button
                    disabled
                    className="feedback-style neutral-feedback d-flex align-items-center gap-1"
                  >
                    <ServiceHoursIcon />
                    <span className="">Neutral</span>
                  </button>
                </div>
              </th>
            </tr>
          </thead>
          {TicketPerformanceData && TicketPerformanceData.length > 0 ? (
            <tbody>
              {TicketPerformanceData.map(ticketPerformance => (
                <tr key={ticketPerformance}>
                  <td className="text-center">{ticketPerformance.user}</td>
                  <td className="text-center">
                    {ticketPerformance.totalTickets}
                  </td>
                  <td className="text-center">
                    {ticketPerformance.solvedTickets}
                  </td>
                  <td className="text-center">
                    {ticketPerformance.unsolvedTickets}
                  </td>

                  <td className="text-center d-flex justify-content-center">
                    <div className="d-flex align-items-center  gap-2 ">
                      {ticketPerformance.solvedTimeAverage?.days > 0 && (
                        <>
                          <div className="d-flex align-items-end justify-content-center text-center gap-1">
                            <span className="fw-bold">
                              {ticketPerformance.solvedTimeAverage.days}
                            </span>
                            <p className="m-0 p-0 ticket-overview-number small-font ">
                              Days{" "}
                            </p>
                          </div>
                          <span className=" m-0 fw-bold">:</span>
                        </>
                      )}
                      {ticketPerformance.solvedTimeAverage?.hours > 0 && (
                        <>
                          <div className="d-flex gap-1 align-items-end justify-content-center text-center">
                            <span className="fw-bold">
                              {ticketPerformance.solvedTimeAverage.hours}
                            </span>
                            <p className="m-0 p-0 ticket-overview-number small-font">
                              hours{" "}
                            </p>
                          </div>
                          <span className="separator">:</span>
                        </>
                      )}
                      {ticketPerformance.solvedTimeAverage?.minutes > 0 && (
                        <div className="d-flex gap-1 align-items-end justify-content-center text-center">
                          <span className="fw-bold">
                            {ticketPerformance.solvedTimeAverage.minutes}
                          </span>
                          <p className="m-0 p-0 ticket-overview-number small-font">
                            Min
                          </p>
                        </div>
                      )}

                      {ticketPerformance.solvedTickets === 0 && (
                        <p className="m-0 p-0 ticket-overview-number">
                          ــــــــ
                        </p>
                      )}
                    </div>
                  </td>
                  <td className="text-center">
                    {ticketPerformance.totalRatedTickets}
                  </td>
                  <td className="text-center">
                    {ticketPerformance.positiveTickets}
                  </td>
                  <td className="text-center">
                    {ticketPerformance.NegativeTickets}
                  </td>
                  <td className="text-center">
                    {ticketPerformance.NeutralTickets}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td colSpan="6" className="text-center p-2">
                  No found
                </td>
              </tr>
            </tbody>
          )}
        </table>
      )}
    </div>
  )
}
