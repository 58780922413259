import React, { useEffect, useState } from "react"
import * as Yup from "yup"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Form,
  Row,
  Col,
  Spinner,
} from "reactstrap"
import { Formik, useFormik, validateYupSchema } from "formik"
import { Toast, ToastBody, ToastHeader } from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import Select from "react-select"
import { PhoneInput, defaultCountries } from "react-international-phone"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"

export default function EditTeam({
  toggle,
  isOpen,
  team,
  setShowEditToast,
  fetchTeamsData,
  setViewTeamComponents,
  toggleViewTeamComponents,
  viewSelectedTeam,
  setViewSelectedTeam,
}) {
  const teamId = team?._id ? team?._id : team?._id

  const [errorMessage, setErrorMessage] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const dispatch = useDispatch()

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  // validateYupSchema
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("required")
      .matches(/^(?!\s+$).*/, "invalid"),
    supervisor: Yup.string().required("required"),
    conversation: Yup.string(),
    serviceHours: Yup.string().required(" required"),
    answersSets: Yup.array().required("required"),
    users: Yup.array(),
  })

  const [viewTeamEdit, setViewTeamEdit] = useState()
  useEffect(() => {
    if (isOpen) {
      axios
        .get(`${Url}/${apiVersion}/teams/${teamId}`, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          setViewTeamEdit(res.data.data.team)
          // setViewTeamComponents(team)
        })
        .catch(error => {
          setIsLoading(false)
          console.error("Error:", error)
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 0 ||
            !error.response.status
          ) {
            dispatch(unAuthUser())
          } else {
            console.log(error)
          }
        })
    }
  }, [team])
  useEffect(() => {
    if (isOpen) {
      axios
        .get(`${Url}/${apiVersion}/teams/${viewSelectedTeam?._id}`, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          setViewTeamEdit(res.data.data.team)
          // setViewTeamComponents(team)
        })
        .catch(error => {
          setIsLoading(false)
          console.error("Error:", error)
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 0 ||
            !error.response.status
          ) {
            dispatch(unAuthUser())
          } else {
            console.log(error)
          }
        })
    }
  }, [viewSelectedTeam])

  const [users, setUsers] = useState()
  const [selectedUser, setSelectedUser] = useState(null)

  useEffect(() => {
    if (isOpen) {
      axios
        .get(`${Url}/${apiVersion}/Users?type=team&teamID=${teamId}`, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          const Allusers = res.data.data.users?.map(user => ({
            value: user._id,
            label: `${user.firstName} ${user.lastName}`,
          }))
          // setTeams(res.data.data.teams)
          setUsers(Allusers)
        })
        .catch(error => {
          if (
            error?.response?.status === 401 ||
            error?.response?.status === 0 ||
            !error.response.status
          ) {
            dispatch(unAuthUser())
          } else {
            console.log(error)
          }
        })
    }
  }, [team])
  const handleUserChange = selectedOption => {
    setSelectedUser(selectedOption)
    setFieldValue("supervisor", selectedOption.value)
  }

  const [selectedMember, setSelectedMember] = useState(null)
  const handleMemberChange = selectedOption => {
    setSelectedMember(selectedOption)
    const selectedUserIds = selectedOption.map(option => option.value)
    setFieldValue("users", selectedUserIds)
  }
  useEffect(() => {
    if (team?.users?.length > 0) {
      const selectedMembersOptions = team.users.map(user => ({
        value: user._id,
        label: `${user.firstName} ${user.lastName}`,
      }))
      setSelectedMember(selectedMembersOptions)
      setFieldValue(
        "users",
        selectedMembersOptions.map(member => member.value)
      )
    }
  }, [team])
  useEffect(() => {
    if (viewSelectedTeam?.users?.length > 0) {
      const selectedMembersOptions = viewSelectedTeam.users.map(user => ({
        value: user._id,
        label: `${user.firstName} ${user.lastName}`,
      }))
      setSelectedMember(selectedMembersOptions)
      setFieldValue(
        "users",
        selectedMembersOptions.map(member => member.value)
      )
    }
  }, [viewSelectedTeam])

  const [conversations, setConversations] = useState([])
  const [selectConversation, setSelectConversation] = useState([])
  const handleConversationChange = selectedOption => {
    setSelectConversation(selectedOption)
    setFieldValue("conversation", selectedOption.value)
  }

  useEffect(() => {
    if (team?.conversation) {
      setSelectConversation({
        value: team.conversation._id,
        label: team.conversation.name,
      })
      setFieldValue("conversation", team.conversation._id)
    }
  }, [team])
  useEffect(() => {
    if (viewSelectedTeam?.conversation) {
      setSelectConversation({
        value: viewSelectedTeam.conversation._id,
        label: viewSelectedTeam.conversation.name,
      })
      setFieldValue("conversation", viewSelectedTeam.conversation._id)
    }
  }, [viewSelectedTeam])

  useEffect(() => {
    setIsLoading(true)
    if (isOpen) {
      axios
        .get(`${Url}/${apiVersion}/conversations`, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          setIsLoading(false)
          //   const AllConversations = res.data.data?.conversations.reverse()
          const AllConversations = res.data.data.conversations?.map(
            conversations => ({
              value: conversations._id,
              label: conversations.name,
            })
          )
          setConversations(AllConversations)
        })
        .catch(error => {
          setIsLoading(false)
          if (
            error.response?.status === 401 ||
            error.response?.status === 0 ||
            !error.response.status
          ) {
            dispatch(unAuthUser())
          } else {
            console.log(error)
          }
        })
    }
  }, [isOpen])

  const [answersSets, setAnswersSets] = useState()
  const [selectedAnswersSet, setSelectedAnswersSet] = useState(null)
  const handleAnswersSetChange = selectedOptions => {
    setSelectedAnswersSet(selectedOptions)
    const selectedAnswerIds = selectedOptions.map(option => option.value)
    setFieldValue("answersSets", selectedAnswerIds)
  }
  useEffect(() => {
    if (team?.answersSets?.length > 0) {
      const selectedAnswersOptions = team.answersSets.map(ans => ({
        value: ans._id,
        label: ans.name,
      }))
      setSelectedAnswersSet(selectedAnswersOptions)
      setFieldValue(
        "answersSets",
        selectedAnswersOptions.map(answer => answer.value)
      )
    } else {
      setSelectedAnswersSet([])
      setFieldValue("answersSets", [])
    }
  }, [team])

  useEffect(() => {
    if (viewSelectedTeam?.answersSets?.length > 0) {
      const selectedAnswersOptions = viewSelectedTeam.answersSets.map(ans => ({
        value: ans._id,
        label: ans.name,
      }))
      setSelectedAnswersSet(selectedAnswersOptions)
      setFieldValue(
        "answersSets",
        selectedAnswersOptions.map(answer => answer.value)
      )
    } else {
      setSelectedAnswersSet([])
      setFieldValue("answersSets", [])
    }
  }, [viewSelectedTeam])

  useEffect(() => {
    setIsLoading(true)
    if (isOpen) {
      axios
        .get(`${Url}/${apiVersion}/answers-sets`, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          setIsLoading(false)
          const AllAnswersSets = res.data.data.answersSets?.map(answer => ({
            value: answer._id,
            label: answer.name,
          }))
          setAnswersSets(AllAnswersSets)
        })

        .catch(error => {
          setIsLoading(false)
          if (
            error.response?.status === 401 ||
            error.response?.status === 0 ||
            !error.response.status
          ) {
            dispatch(unAuthUser())
          } else {
            console.log(error)
          }
        })
    }
  }, [isOpen])

  const [serviceHours, setServiceHours] = useState([])
  const [selectedServiceHours, setSelectedServiceHours] = useState([])
  const handleServiceHoursChange = selectedOption => {
    setSelectedServiceHours(selectedOption)
    setFieldValue("serviceHours", selectedOption.value)
  }
  useEffect(() => {
    setIsLoading(true)
    if (isOpen) {
      axios
        .get(`${Url}/${apiVersion}/services`, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          setIsLoading(false)
          //   const AllConversations = res.data.data?.conversations.reverse()
          const AllServiceHoursData = res.data.data.services?.map(
            serviceHour => ({
              value: serviceHour._id,
              label: serviceHour.name,
            })
          )

          setServiceHours(AllServiceHoursData)
        })
        .catch(error => {
          setIsLoading(false)
          if (
            error.response?.status === 401 ||
            error.response?.status === 0 ||
            !error.response.status
          ) {
            dispatch(unAuthUser())
          } else {
            console.log(error)
          }
        })
    }
  }, [isOpen])

  useEffect(() => {
    setFieldValue("name", team?.name || "")
    if (team?.supervisor) {
      setSelectedUser({
        value: team.supervisor._id,
        label: `${team.supervisor.firstName} ${team.supervisor.lastName}`,
      })
      setFieldValue("supervisor", team.supervisor._id)
    }
    if (team?.serviceHours) {
      setSelectedServiceHours({
        value: team.serviceHours._id,
        label: team.serviceHours.name,
      })
      setFieldValue("serviceHours", team.serviceHours._id)
    }
  }, [team])
  useEffect(() => {
    setFieldValue("name", viewSelectedTeam?.name || "")
    if (viewSelectedTeam?.supervisor) {
      setSelectedUser({
        value: viewSelectedTeam.supervisor._id,
        label: `${viewSelectedTeam.supervisor.firstName} ${viewSelectedTeam.supervisor.lastName}`,
      })
      setFieldValue("supervisor", team.supervisor._id)
    }
    if (viewSelectedTeam?.serviceHours) {
      setSelectedServiceHours({
        value: viewSelectedTeam.serviceHours._id,
        label: viewSelectedTeam.serviceHours.name,
      })
      setFieldValue("serviceHours", viewSelectedTeam.serviceHours._id)
    }
  }, [viewSelectedTeam])

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "gray" : "white",
      backgroundColor: state.isFocused ? "#EBEDF0" : "white",
      color: state.isSelected ? "gray" : "black",
    }),
    menu: provided => ({
      ...provided,
      zIndex: 10000,
      height: "1rem",
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: "10rem",
      overflowY: "auto",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    }),
  }
  // Formik
  const initialValues = viewSelectedTeam
    ? {
        id: viewSelectedTeam?._id || "",
        name: viewSelectedTeam?.name || "",
        supervisor: viewSelectedTeam?.supervisor?._id || "",
        serviceHours: viewSelectedTeam?.serviceHours?._id || "",
        conversation: viewSelectedTeam?.conversation || "",
        answersSets: viewSelectedTeam?.answersSets
          ? viewSelectedTeam?.answersSets.map(answer => answer._id)
          : "",
        users:
          viewSelectedTeam?.users?.length > 0
            ? viewSelectedTeam?.users.map(viewUser => viewUser._id)
            : [],
      }
    : {
        name: team?.name || "",
        supervisor: team?.supervisor?._id || "",
        serviceHours: team?.serviceHours?._id || "",
        conversation: team?.conversation || "",
        answersSets: team?.answersSets
          ? team?.answersSets.map(answer => answer._id)
          : "",
        users:
          team?.users?.length > 0
            ? team?.users.map(teamUser => teamUser._id)
            : [],
        ticketRequests: team?.ticketRequests || [],
      }
  const {
    handleChange,
    values,
    handleSubmit,
    setFieldValue,
    resetForm,
    errors,
    handleBlur,
    touched,
  } = useFormik({
    // initialValues: {
    //   name: team?.name || "",
    //   supervisor: team?.supervisor?._id || "",
    //   serviceHours: team?.serviceHours?._id || "",
    //   conversation: team?.conversation || "",
    //   answersSets: team?.answersSets?.map(answer => answer._id) || "",
    //   users:
    //     team?.users?.length > 0 ? team.users.map(teamUser => teamUser._id) : [],
    // },
    initialValues,

    validationSchema,
    onSubmit: values => {
      setIsLoading(true)
      setErrorMessage()

      axios
        .patch(`${Url}/${apiVersion}/teams/${team._id}`, values, {
          headers: {
            "Content-Type": "application/json; charset=utf8",
            Authorization: `Bearer ${access}`,
          },
        })
        .then(res => {
          if (res.status === 201 || res.status === 200) {
            toggle()
            setIsLoading(false)
            fetchTeamsData()
            resetForm()
            setShowEditToast(true)
            setViewTeamComponents(true)
            toggleViewTeamComponents(viewTeamEdit)
            setTimeout(() => setShowEditToast(false), 2500)
          } else {
            console.error("Unexpected status code:", res.status, res.data)
            setIsLoading(false)
          }
        })
        .catch(error => {
          setIsLoading(false)

          if (
            error.response?.status === 401 ||
            error.response?.status === 0 ||
            !error.response.status
          ) {
            dispatch(unAuthUser())
          } else if (error.response?.status === 403) {
            setErrorMessage(error.response.data.message)
            setTimeout(() => setErrorMessage(false), 3500)
          } else {
            setErrorMessage(error.response?.data.message)
            setTimeout(() => setErrorMessage(false), 3500)
            console.log(error)
          }
        })
    },
  })

  //ticket requests
  const ticketRequestsOptions = [
    { value: "RD0", label: "RD0" },
    { value: "Edit RD0", label: "Edit RD0" },
    { value: "Missing Data", label: "Missing Data" },
    { value: "Design Review", label: "Design Review" },
    { value: "RD6", label: "RD6" },
    { value: "RD7", label: "RD7" },
    { value: "Finance", label: "Finance" },
    { value: "Inspection", label: "Inspection" },
    { value: "MALATH Issue", label: "MALATH Issue" },
    { value: "MALATH Complaint", label: "MALATH Complaint" },
    { value: "Other", label: "Other" },
  ]
  useEffect(() => {
    if (team?.ticketRequests) {
      const defaultValues = team.ticketRequests.map(value => ({
        value,
        label:
          ticketRequestsOptions.find(option => option.value === value)?.label ||
          value,
      }))
      setSelectedTicket(defaultValues)
    }
  }, [team])
  const [selectedTicket, setSelectedTicket] = useState([])
  const handleTicketChange = selectedOptions => {
    setSelectedTicket(selectedOptions)
    const selectedValues = selectedOptions
      ? selectedOptions.map(option => option.value)
      : []
    setFieldValue("ticketRequests", selectedValues)
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }
  return (
    <div>
      <Modal isOpen={isOpen} toggle={toggle} centered size="lg">
        <ModalHeader toggle={toggle}>Edit Team</ModalHeader>
        <ModalBody>
          {errorMessage && (
            <div className="" aria-live="polite" aria-atomic="true">
              <div className="">
                <Toast isOpen={errorMessage} fade={true}>
                  <ToastBody className="delete-message-bg">
                    <h5 className="m-0 mb-2">Error</h5>
                    <p className="m-0">{errorMessage}</p>
                  </ToastBody>
                </Toast>
              </div>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-1">Team Name</Label>
              <Input
                className=" p-5px m-0"
                id="team-name"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                // placeholder="Email"
                type="text"
              />
              {errors.name && touched.name && values?.name?.trim() === "" && (
                <div className="error mb-0">{errors.name}</div>
              )}
            </FormGroup>

            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-1">Supervisor </Label>
              <Select
                className=" m-0 "
                id="supervisor"
                name="supervisor"
                value={selectedUser}
                onChange={handleUserChange}
                options={users}
                styles={customStyles}
              ></Select>

              {errors.supervisor && touched.supervisor && (
                <div className="error mb-0">{errors.supervisor}</div>
              )}
            </FormGroup>
            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-1">Members </Label>
              <Select
                className=" m-0 "
                id="users"
                name="users"
                // defaultValue={team?.users?.map(teamUser => ({
                //   label: teamUser.firstName,
                //   value: teamUser._id,
                // }))}
                value={selectedMember}
                onChange={handleMemberChange}
                options={users}
                styles={customStyles}
                isMulti
              ></Select>

              {errors.users && touched.users && (
                <div className="error mb-0">{errors.users}</div>
              )}
            </FormGroup>

            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-0">Service Hours</Label>
              <Select
                className=" m-0"
                id="serviceHours"
                name="serviceHours"
                value={selectedServiceHours}
                onChange={handleServiceHoursChange}
                options={serviceHours}
                styles={customStyles}
              ></Select>
              {errors.serviceHours && touched.serviceHours && (
                <div className="error mb-0">{errors.serviceHours}</div>
              )}
            </FormGroup>

            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-0">Conversation </Label>
              <Select
                className=" m-0"
                id="conversation"
                name="conversation"
                value={selectConversation}
                options={conversations}
                onChange={handleConversationChange}
                styles={customStyles}
              ></Select>
              {errors.conversation && touched.conversation && (
                <div className="error mb-0">{errors.conversation}</div>
              )}
            </FormGroup>

            <FormGroup className="m-0 m-mb-2">
              <Label className="mb-0">Suggested Answers </Label>
              <Select
                className=" m-0"
                id="answersSets"
                name="answersSets"
                value={selectedAnswersSet}
                options={answersSets}
                onChange={handleAnswersSetChange}
                styles={customStyles}
                isMulti
              ></Select>
              {errors.answersSets && touched.answersSets && (
                <div className="error mb-0">{errors.answersSets}</div>
              )}
            </FormGroup>

            <Row>
              <Col>
                <FormGroup className="m-0 m-mb-2">
                  <Label className="mb-0">ticket Requests</Label>
                  <Select
                    isMulti
                    className="p-5px m-0"
                    id="ticketRequests"
                    name="ticketRequests"
                    value={selectedTicket}
                    options={ticketRequestsOptions}
                    onChange={handleTicketChange}
                    styles={customStyles}
                  ></Select>
                </FormGroup>
              </Col>
            </Row>

            {isLoading && (
              <div className="loading-overlay">
                <Spinner />
              </div>
            )}

            <FormGroup className=" d-flex justify-content-end gap-3 edit-popup ">
              <Button type="submit" className="edit-button">
                Edit
              </Button>
              <Button onClick={toggle} className="edit-button cancel-delete ">
                Cancel
              </Button>
            </FormGroup>
          </form>
        </ModalBody>
      </Modal>
    </div>
  )
}
