import React, { useEffect, useRef, useState } from "react"
import axios from "axios"
import {
  Container,
  Row,
  Col,
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Form,
  FormGroup,
  Input,
  Button,
  Badge,
  Label,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  ButtonDropdown,
  Card,
  CardBody,
} from "reactstrap"
import Select from "react-select"
import "./TicketClient/TicketClientstyle.css"
import { useSelector } from "react-redux"
import configs from "config"
import TicketClientLog from "./TicketClient/TicketClientLog"
import SingleComment from "./TicketList/TicketComments/SingleComment"
import CustomSpinner from "components/Common/CustomSpinner"
const { Url, apiVersion } = configs.client
import NoMessageFound from "../../components/Common/Icons/Chat/Conversation/no-message-found.png"
import { useParams } from "../../../node_modules/react-router-dom/dist/index"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function TicketClient() {
  const { access, authorized, userData } = useSelector(state => ({
    access: state.Login?.userAccess,
    authorized: state.Login.authorized,
    userData: state.Login.userData,
  }))

  // Set all accordion panels (1, 2, 3) to be open by default
  const [open, setOpen] = useState(["1", "2", "3"])
  const [ticketData, setTicketData] = useState(null)
  const [ticketLogData, setTicketLogData] = useState([])
  const [loadingData, setLoadingData] = useState(true)
  const [editedQuestions, setEditedQuestions] = useState(null)
  const [noCommentsFound, setNoCommentsFound] = useState(false)
  const [comments, setComments] = useState([])
  const [selectedFiles, setSelectedFiles] = useState([])
  const [commentText, setCommentText] = useState("")
  const [drp_link, setdrp_link] = useState(false)
  const [statuses, setStatuses] = useState(null)
  const [clientFeedbackRating, setClientFeedbackRating] = useState("")
  const [feedbackText, setFeedbackText] = useState("")
  const [sendingFeedback, setSendingFeedback] = useState(false)
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false)

  const commentsEndRef = useRef(null)
  const params = useParams()

  const toggle = id => {
    if (open.includes(id)) {
      setOpen(open.filter(item => item !== id))
    } else {
      setOpen([...open, id])
    }
  }

  const fetchTicketData = () => {
    setLoadingData(true)

    axios
      .get(`${Url}/${apiVersion}/client`, {
        headers: {
          Authorization: `Bearer ${params.clientToken}`,
        },
      })
      .then(res => {
        setTicketData(res.data.data.ticket)
        setTicketLogData(res.data.data.ticketLogs)
        setComments(res.data.data.ticketComments)

        res.data.data.ticket.feedback &&
          setFeedbackText(res.data.data.ticket.feedback)

        res.data.data.ticket.rating &&
          setClientFeedbackRating(res.data.data.ticket.rating)

        res.data.data.ticketComments.length === 0
          ? setNoCommentsFound(true)
          : setNoCommentsFound(false)

        setLoadingData(false)
        setEditedQuestions(
          res.data.data.ticket?.questions?.map(question => ({
            fieldId: question.field?._id,
            answer: question.answer.join(", "),
          }))
        )
      })
      .catch(err => {
        setLoadingData(false)

        if (
          err.response.status === 401 ||
          err.response.status === 0 ||
          !err.response?.status
        ) {
          dispatch(unAuthUser())
        } else {
          console.log(err)
        }
      })
  }

  const submitFeedback = () => {
    setSendingFeedback(true)

    let data = {}

    if (clientFeedbackRating) {
      data.rating = clientFeedbackRating
    }

    if (feedbackText) {
      data.feedback = feedbackText
    }

    axios
      .patch(`${Url}/${apiVersion}/client/feedback`, data, {
        headers: {
          Authorization: `Bearer ${params.clientToken}`,
        },
      })
      .then(res => {
        setSendingFeedback(false)
        setFeedbackSubmitted(true)
      })
      .catch(err => {
        setSendingFeedback(false)

        // if (err.response.status === 401 || err.response.status === 0) {
        //   dispatch(unAuthUser())
        // } else {
        //   console.log(err)
        // }
      })
  }

  const handleFileSelect = event => {
    const files = Array.from(event.target.files)
    setSelectedFiles(prevFiles => [...prevFiles, ...files])
  }

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#f0f0f0" : "#fff", // Highlight focused option, otherwise white
      color: state.isSelected ? "#333" : "#000", // Change text color if selected
    }),
    control: provided => ({
      ...provided,
      borderColor: "#ddd", // Custom border color for the select input
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: "#fff", // Make sure the dropdown menu is white
    }),
  }

  const renderField = (field, index, editable = false) => {
    const isEmptyRequiredField =
      field?.required &&
      (!editedQuestions[index].answer ||
        editedQuestions[index].answer.trim() === "")

    return (
      <FormGroup key={field?._id}>
        <Label for={field?._id}>
          {field?.name}{" "}
          {field?.required && <span className="text-danger">*</span>}
        </Label>
        {(() => {
          switch (field?.type.value) {
            case "dropdown":
              return (
                <Select
                  id={field?._id}
                  value={
                    editedQuestions[index].answer
                      ? {
                          value: editedQuestions[index].answer,
                          label: editedQuestions[index].answer,
                        }
                      : null
                  }
                  onChange={selectedOption =>
                    handleInputChange(
                      index,
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  options={field?.values.map(value => ({
                    value,
                    label: value,
                  }))}
                  isDisabled={!editable}
                  isClearable
                  placeholder={`Select ${field?.name}`}
                  className="basic-select"
                  classNamePrefix="select"
                  styles={customStyles}
                />
              )
            case "text":
              return (
                <Input
                  type="text"
                  id={field?._id}
                  value={editedQuestions[index].answer}
                  placeholder={field?.name}
                  onChange={e => handleInputChange(index, e.target.value)}
                  readOnly={!editable}
                />
              )
            case "number":
              return (
                <Input
                  type="text"
                  id={field?._id}
                  value={editedQuestions[index].answer}
                  placeholder={field?.name}
                  pattern="[0-9]*"
                  onInput={e => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "")
                  }}
                  onChange={e => handleInputChange(index, e.target.value)}
                  readOnly={!editable}
                />
              )
            case "text-area":
              return (
                <Input
                  type="textarea"
                  id={field?._id}
                  value={editedQuestions[index].answer}
                  placeholder={field?.name}
                  onChange={e => handleInputChange(index, e.target.value)}
                  readOnly={!editable}
                />
              )
            case "checkbox":
              return (
                <FormGroup check className="mb-2">
                  <Label check>
                    <Input
                      type="checkbox"
                      className="d-block"
                      id={field?._id}
                      checked={editedQuestions[index].answer}
                      onClick={e => handleInputChange(index, e.target.checked)}
                      disabled={!editable}
                    />{" "}
                    {field?.name}{" "}
                    {field?.required && <span className="text-danger">*</span>}
                  </Label>
                </FormGroup>
              )
            case "multi-select":
              return (
                <Select
                  isMulti
                  name={field?._id}
                  options={field?.values.map(value => ({
                    value,
                    label: value,
                  }))}
                  value={editedQuestions[index].answer
                    .split(", ")
                    .map(value => ({
                      value,
                      label: value,
                    }))} // Use the current state for the value
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={selectedOptions => {
                    const values = selectedOptions.map(option => option.value)
                    handleInputChange(index, values.join(", "))
                  }}
                  isDisabled={!editable}
                />
              )
            case "decimal":
              return (
                <Input
                  type="text"
                  id={field?._id}
                  value={editedQuestions[index].answer}
                  placeholder={field?.name}
                  onChange={e => handleInputChange(index, e.target.value)}
                  onInput={e => {
                    const value = e.target.value
                    e.target.value = value.match(/^\d*\.?\d{0,4}$/)
                      ? value
                      : value.slice(0, -1)
                  }}
                  readOnly={!editable}
                />
              )
            case "date":
              return (
                <Input
                  type="date"
                  id={field?._id}
                  value={editedQuestions[index].answer}
                  onChange={e => handleInputChange(index, e.target.value)}
                  readOnly={!editable}
                />
              )
            default:
              return null
          }
        })()}

        {isEmptyRequiredField && (
          <div className="text-danger mt-1">Required.</div>
        )}
      </FormGroup>
    )
  }

  useEffect(() => {
    fetchTicketData()
  }, [])

  return (
    <div className="ticket-client-page">
      <div className="py-5 shadow ticket-page-header-container position-relative">
        <div className="px-5">
          <h3 className="mb-0 ticket-client-heading">Good Morning</h3>
          <p className="ticket-client-page-heading-paragraph">
            You can track all your ticket's data here.
          </p>
        </div>

        <div
          className={`bg-white p-3 rounded mb-3 shadow rounded-3 position-absolute ticket-client-page-feedback ${
            submitFeedback && "text-center py-4"
          }`}
        >
          {sendingFeedback ? (
            <CustomSpinner />
          ) : feedbackSubmitted ? (
            <div className="py-5 mt-2">
              <i className="fas fa-check-circle fa-10x text-center text-success"></i>

              <h4 className="mt-2">
                Feedback received!
                <br /> Thank you!
              </h4>
            </div>
          ) : (
            <>
              <h5 className="font-size-16 mb-3" style={{ color: "#6B7889" }}>
                How was your experience?
              </h5>

              <FormGroup
                className="d-flex justify-content-around"
                style={{ marginBottom: "0" }}
              >
                {/* Thumbs Up */}
                <div
                  onClick={() => setClientFeedbackRating("Positive")}
                  className={`text-center single-feedback ${
                    clientFeedbackRating === "Positive" && "active"
                  }`}
                >
                  <span
                    role="img"
                    aria-label="Positive"
                    style={{ fontSize: "2rem" }}
                  >
                    👍
                  </span>
                  <p className="mb-0">Positive</p>
                </div>

                {/* Thumbs Down */}
                <div
                  onClick={() => setClientFeedbackRating("Negative")}
                  className={`text-center single-feedback ${
                    clientFeedbackRating === "Negative" && "active"
                  }`}
                >
                  <span
                    role="img"
                    aria-label="Negative"
                    style={{ fontSize: "2rem" }}
                  >
                    👎
                  </span>
                  <p className="mb-0">Negative</p>
                </div>

                {/* Okay */}
                <div
                  onClick={() => setClientFeedbackRating("Neutral")}
                  className={`text-center single-feedback ${
                    clientFeedbackRating === "Neutral" && "active"
                  }`}
                >
                  <span
                    role="img"
                    aria-label="Neutral"
                    style={{ fontSize: "2rem" }}
                  >
                    👌
                  </span>
                  <p className="mb-0">Neutral</p>
                </div>
              </FormGroup>

              <p className="text-muted font-size-12">Choose your experience</p>

              {/* Suggestion Input */}
              <FormGroup>
                <Input
                  type="textarea"
                  name="suggestions"
                  id="suggestions"
                  rows="4"
                  className="rounded-3"
                  value={feedbackText}
                  placeholder="Suggest anything we can improve..."
                  onChange={e => {
                    setFeedbackText(e.target.value)
                  }}
                />
              </FormGroup>

              {/* Submit Button */}
              <Button
                disabled={!clientFeedbackRating}
                onClick={() => submitFeedback()}
                color="secondary"
                className="py-3 font-size-14 rounded-4"
                block
              >
                Send Feedback
              </Button>
            </>
          )}
        </div>
      </div>

      <Row className="g-3 p-5">
        {/* First Column with Ticket Details Accordion */}
        <Col sm="12" md="4">
          {/* Feedback Section */}

          <Accordion open={open} toggle={toggle} className="bg-light">
            <AccordionItem
              style={{ backgroundColor: "#F8F8FB", border: "none" }}
            >
              <AccordionHeader
                targetId="1"
                className="client-ticket-custom-accordion-header shadow-lg mb-2"
                style={{
                  backgroundColor: "#fff",
                  padding: ".5rem",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                Ticket Details
              </AccordionHeader>
              <AccordionBody
                accordionId="1"
                className="client-ticket-custom-accordion-body"
                style={{
                  backgroundColor: "#f8f9fa",
                  padding: "1rem",
                  borderRadius: "8px",
                  border: "1px solid #dee2e6",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
                  maxHeight: "600px",
                  minHeight: "600px",
                  overflow: "auto",
                }}
              >
                {loadingData ? (
                  <CustomSpinner />
                ) : (
                  <>
                    <div className="mb-3 d-flex justify-content-start">
                      <h5 className="mb-0">Ticket Info</h5>
                    </div>

                    <div className="">
                      <h6 className="text-muted">
                        Status <span className="text-danger">*</span>
                      </h6>

                      <p className="border border-secondary border-opacity-25 rounded-1 p-1">
                        {ticketData?.status?.name?.toLowerCase() ==
                          "pending" && (
                          <Badge
                            pill
                            className="badge-soft-warning ms-1 font-size-12"
                          >
                            <span className="font-size-16 me-1">&#9679;</span>{" "}
                            Pending
                          </Badge>
                        )}

                        {ticketData?.status?.name?.toLowerCase() ==
                          "solved" && (
                          <Badge
                            pill
                            className="badge-soft-secondary ms-1 font-size-12"
                          >
                            <span className="font-size-16 me-1">&#9679;</span>{" "}
                            Solved
                          </Badge>
                        )}

                        {ticketData?.status?.name?.toLowerCase() == "new" && (
                          <Badge
                            pill
                            className="badge-soft-danger ms-1 font-size-12"
                          >
                            <span className="font-size-16 me-1">&#9679;</span>{" "}
                            New
                          </Badge>
                        )}

                        {ticketData?.status?.name?.toLowerCase() == "open" && (
                          <Badge
                            pill
                            className="badge-soft-success ms-1 font-size-12"
                          >
                            <span className="font-size-16 me-1">&#9679;</span>{" "}
                            Open
                          </Badge>
                        )}

                        {(!ticketData?.status?.name ||
                          (ticketData?.status?.name?.toLowerCase() !== "new" &&
                            ticketData?.status?.name?.toLowerCase() !==
                              "open" &&
                            ticketData?.status?.name?.toLowerCase() !==
                              "solved" &&
                            ticketData?.status?.name?.toLowerCase() !==
                              "pending")) && (
                          <Badge
                            pill
                            className="badge-soft-dark ms-1 font-size-12"
                          >
                            <span className="font-size-16 me-1">&#9679;</span>{" "}
                            {ticketData?.status?.name || "Unknown"}
                          </Badge>
                        )}
                      </p>
                    </div>

                    <div className="">
                      <h6 className="text-muted">
                        Reference No <span className="text-danger">*</span>
                      </h6>

                      <p className="border border-secondary border-opacity-25 rounded-1 p-1">
                        {ticketData?.refNo
                          ? ticketData?.refNo
                          : "Enter a ref number"}
                      </p>
                    </div>

                    <div className="">
                      <h6 className="text-muted">
                        Category <span className="text-danger">*</span>
                      </h6>

                      <p className="border border-secondary border-opacity-25 rounded-1 p-1">
                        {ticketData?.category?.name
                          ? ticketData?.category?.name
                          : "Choose a category name"}
                      </p>
                    </div>

                    <div className="my-3 d-flex justify-content-start">
                      <h5 className="mb-0">Form</h5>
                    </div>

                    {ticketData?.questions?.map((question, index) =>
                      renderField(question.field, index, false)
                    )}
                  </>
                )}
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </Col>

        {/* Second Column with Ticket Logs Accordion */}
        <Col sm="12" md="4">
          <Accordion open={open} toggle={toggle} className="bg-light">
            <AccordionItem
              style={{ backgroundColor: "#F8F8FB", border: "none" }}
            >
              <AccordionHeader
                targetId="2"
                className="client-ticket-custom-accordion-header shadow-lg mb-2"
                style={{
                  backgroundColor: "#fff",
                  padding: ".5rem",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                Ticket Logs
              </AccordionHeader>
              <AccordionBody
                accordionId="2"
                className="client-ticket-custom-accordion-body"
                style={{
                  backgroundColor: "#f8f9fa",
                  padding: "1rem",
                  borderRadius: "8px",
                  border: "1px solid #dee2e6",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
                  maxHeight: "600px",
                  minHeight: "600px",
                  overflow: "auto",
                }}
              >
                <TicketClientLog
                  ticketLogData={ticketLogData}
                  loading={loadingData}
                />
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </Col>

        {/* Third Column with Feedback Section and Ticket Comments Accordion */}
        <Col sm="12" md="4" className="ticket-client-page-third-col">
          {/* Ticket Comments Accordion */}
          <Accordion open={open} toggle={toggle} className="bg-light">
            <AccordionItem
              style={{ backgroundColor: "#F8F8FB", border: "none" }}
            >
              <AccordionHeader
                targetId="3"
                className="client-ticket-custom-accordion-header shadow-lg mb-2"
                style={{
                  backgroundColor: "#fff",
                  padding: ".5rem",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                Ticket Comments
              </AccordionHeader>
              <AccordionBody
                accordionId="3"
                className="client-ticket-custom-accordion-body"
                style={{
                  backgroundColor: "#f8f9fa",
                  padding: "1rem 0",
                  borderRadius: "8px",
                  border: "1px solid #dee2e6",
                  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.05)",
                  maxHeight: "600px",
                  minHeight: "600px",
                  overflow: "auto",
                }}
              >
                <Card
                  className="px-1 shadow-none rounded-0"
                  style={{ marginBottom: "0" }}
                >
                  <CardBody className="shadow-none">
                    <div className="d-flex justify-content-between align-items-center pb-2">
                      <h5 className="border-bottom mb-0 text-muted">
                        {ticketData?.order
                          ? `Ticket No #${ticketData?.order}`
                          : "Ticket Number"}
                      </h5>

                      <span></span>
                    </div>

                    {/* Comments Section */}
                    <Card className="my-3 shadow" style={{ marginBottom: "0" }}>
                      <CardBody
                        className="shadow"
                        style={{
                          padding: "1rem 1.25rem",
                          height: "calc(7px + 35vh)",
                          maxHeight: "calc(7px + 35vh)",
                          overflow: "auto",
                        }}
                      >
                        {noCommentsFound ? (
                          <div className="align-self-center d-flex flex-column justify-content-center mw-50 my-auto">
                            <img
                              className="mx-auto"
                              style={{ height: "13.25rem" }}
                              src={NoMessageFound}
                            />
                            <h6 className="mx-auto font-size-20">
                              No Comments Yet!
                            </h6>
                          </div>
                        ) : loadingData ? (
                          <CustomSpinner />
                        ) : (
                          comments?.map(comment => (
                            <SingleComment
                              key={comment._id}
                              comment={comment}
                            />
                          ))
                        )}

                        <div ref={commentsEndRef} />
                      </CardBody>
                    </Card>

                    {/* Input and Buttons Section */}
                    <Card className="shadow" style={{ marginBottom: "0" }}>
                      <CardBody
                        className="shadow position-relative"
                        style={{
                          padding: "1rem 1.25rem",
                          // backgroundColor: isPrivate
                          //   ? "rgb(255 221 164)"
                          //   : "#FFFFFF",
                        }}
                      >
                        {selectedFiles.length > 0 && renderSelectedFiles()}

                        <Input
                          type="textarea"
                          name="commentinput"
                          placeholder="Write your comment here"
                          className="mb-2"
                          style={{ border: "none" }}
                          value={commentText}
                          bsSize="lg"
                          onChange={e => setCommentText(e.target.value)}
                        />

                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <Button
                              outline
                              color="secondary"
                              className="me-2"
                              onClick={() =>
                                document.getElementById("fileInput").click()
                              }
                            >
                              <i className="mdi mdi-attachment me-1"></i> Attach
                            </Button>
                            <input
                              type="file"
                              id="fileInput"
                              style={{ display: "none" }}
                              multiple
                              onChange={handleFileSelect}
                            />

                            <ButtonDropdown
                              isOpen={drp_link}
                              toggle={() => setdrp_link(!drp_link)}
                            >
                              <Button
                                id="caret"
                                color="secondary"
                                onClick={() => handleSendComment(null)}
                                disabled={
                                  !commentText && selectedFiles.length === 0
                                }
                              >
                                Submit
                              </Button>
                              <DropdownToggle
                                caret
                                color="secondary"
                                title="Change Ticket's Status"
                                disabled={
                                  !commentText && selectedFiles.length === 0
                                }
                              >
                                <i className="mdi mdi-chevron-down" />
                              </DropdownToggle>
                              <DropdownMenu>
                                {statuses?.map(status => (
                                  <DropdownItem
                                    key={status._id}
                                    onClick={() =>
                                      handleSendComment(status._id)
                                    }
                                  >
                                    {status.name}
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </ButtonDropdown>
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  </CardBody>
                </Card>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        </Col>
      </Row>
    </div>
  )
}

export default TicketClient
