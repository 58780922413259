import React, { PureComponent, useEffect, useState } from "react"
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Label,
  Legend,
  Tooltip,
} from "recharts"
import { Col, Row } from "reactstrap"
import DatePicker from "react-datepicker"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import { useSelector } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import { useDispatch } from "react-redux"
import moment from "moment"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function TicketPriority({ startDate, endDate }) {
  const [isloading, setIsLoading] = useState(false)
  const [ticketPriority, setTicketPriority] = useState([])

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const dispatch = useDispatch()

  const fetchAllTicketPriority = async () => {
    try {
      setIsLoading(true)
      const params = {
        startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
        endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      }
      axios
        .get(`${Url}/${apiVersion}/ticket-dashboard/priority`, {
          headers: { Authorization: `Bearer ${access}` },
          params: params,
        })
        .then(response => {
          const AllTickerPriority = response.data.data

          const dataArray = Object.keys(AllTickerPriority)
            .filter(key => key !== "total")
            .map(key => ({
              name: key,
              value: AllTickerPriority[key],
            }))
          setTicketPriority(dataArray)
          setIsLoading(false)
        })
        .catch(err => {
          if (
            err.response?.status == 401 ||
            err.response?.status == 0 ||
            !err.response.status
          ) {
            dispatch(unAuthUser())
          } else {
            console.log(err)
            toast.error("Error: Unable to load data. Please try again later.")
          }
        })
    } catch (error) {
      console.error("Unexpected error:", error)
    }
  }

  useEffect(() => {
    fetchAllTicketPriority()
  }, [startDate, endDate])

  const COLORS = ["#EB6363", "#FFC061", "#3879F0", "#2DB976"]
  const totalValue = ticketPriority.reduce((sum, entry) => sum + entry.value, 0)

  const CustomLegendFormatter = (value, entry) => {
    const nameColor = "#9291A5"
    const valueColor = "#6B7889"
    return (
      <span style={{ marginRight: "20px" }}>
        <span style={{ color: nameColor, marginRight: "3px" }}>{value}</span>{" "}
        <span style={{ color: valueColor, fontWeight: "bold" }}>
          {entry.payload.value}
        </span>
      </span>
    )
  }

  const CustomTooltip = ({ payload, label }) => {
    if (!payload || !payload.length) return null
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#5A6778",
          color: "#FFFFFF",
          padding: "7px",
          borderRadius: "4px",
        }}
      >
        <p className="value m-0">{`${Math.round(
          (payload[0].value / totalValue) * 100
        )} %`}</p>
      </div>
    )
  }

  const CustomLabel = ({ viewBox }) => {
    const { cx, cy } = viewBox
    return (
      <g>
        <text
          x={cx}
          y={cy - 15}
          textAnchor="middle"
          fill="#9291A5"
          fontSize="10"
        >
          Total Tickets
        </text>

        <text
          x={cx}
          y={cy + 5}
          textAnchor="middle"
          fontSize="16"
          fontWeight="bold"
          fill="#6B7889"
        >
          {totalValue}
        </text>
      </g>
    )
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <>
      {isloading ? (
        <div className="loading d-flex justify-content-center align-items-center">
          <div className="spinner-border d-flex justify-content-center align-items-center"></div>
        </div>
      ) : ticketPriority &&
        ticketPriority.length > 0 &&
        ticketPriority.some(item => item.value > 0) ? (
        <ResponsiveContainer width="100%" height={170}>
          <PieChart>
            <Pie
              data={ticketPriority}
              cx="50%"
              cy={110}
              startAngle={180}
              endAngle={0}
              innerRadius={66}
              outerRadius={92.4}
              dataKey="value"
              stroke="none"
            >
              {ticketPriority.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
              <Label content={<CustomLabel />} />
            </Pie>
            <Legend
              align="center"
              width="100%"
              wrapperStyle={{
                marginTop: "20px",
                borderTop: "0.6px solid rgba(229, 229, 239, 1)",
                paddingTop: "10px",
              }}
              layout="horizontal"
              iconType="circle"
              formatter={CustomLegendFormatter}
              iconSize={6}
            />
            <Tooltip content={<CustomTooltip />} />
          </PieChart>
        </ResponsiveContainer>
      ) : ticketPriority && ticketPriority.length > 0 ? (
        <div className=" d-flex flex-column align-items-center justify-content-center">
          <ResponsiveContainer width="100%" height={150}>
            <PieChart>
              <Pie
                data={[{ value: 1 }]}
                cx="50%"
                cy={110}
                startAngle={180}
                endAngle={0}
                innerRadius={66}
                outerRadius={92.4}
                dataKey="value"
                stroke="none"
              >
                <Cell fill="#e0e0e0" />
                <Label content={<CustomLabel />} />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <div className=" py-2 border-priority w-100 d-flex justify-content-center align-items-center">
            <div className="d-flex justify-content-center align-items-centerflex-wrap gap-3  ">
              {ticketPriority.map((ticket, idx) => (
                <div key={idx} className="d-flex align-items-center  ">
                  <div
                    style={{
                      backgroundColor: "#e0e0e0",
                      width: "6px",
                      height: "6px",
                      borderRadius: "50%",
                      marginRight: "5px",
                    }}
                  ></div>
                  <p className="m-0">{ticket.name}</p>
                  <span className="ms-2">{ticket.value}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <p className="d-flex justify-content-center align-items-center mb-2 fw-bold p-3 fs-5">
          No data available
        </p>
      )}
    </>
  )
}
