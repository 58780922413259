import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import SearchIcon from "components/Common/Icons/SettingIcons/SearchIcon"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../config"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import UnAuthorized from "store/auth/logout/UnAuthorized"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import CategoriesTable from "./TicketCategories/CategoriesTable"
import { useLocation, useMatch, useNavigate, useParams } from "react-router-dom"
import AddCategories from "./TicketCategories/AddCategories"
import { Toast, ToastBody, ToastHeader } from "reactstrap"
import EditCategories from "./TicketCategories/EditCategories"
import StatusUser from "./TicketCategories/StatusCaregory"
import StatusCategory from "./TicketCategories/StatusCaregory"
import { unAuthUser } from "store/actions"
// import UnAuthorized from "store/auth/logout/UnAuthorized"
// import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"

import { toast, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function TicketCategories() {
  const [showSuccessToast, setShowSuccessToast] = useState(false)
  const [showEditToast, setShowEditToast] = useState(false)

  const [modalAddNewCategory, setModalAddNewCategory] = useState(false)
  const toggleAdd = () => {
    setModalAddNewCategory(!modalAddNewCategory)
  }

  const [modalEditCategory, setModalEditCategory] = useState(false)
  const [editCategory, setEditCategory] = useState(null)
  const toggleEdit = Category => {
    setEditCategory(Category)
    setModalEditCategory(!modalEditCategory)
  }

  const [modalStatusCategory, setModalStatusCategory] = useState(false)
  const [activeCategory, setActiveCategory] = useState(null)
  const toggleActiveCategory = category => {
    setActiveCategory(category)
    setModalStatusCategory(!modalStatusCategory)
  }

  const [isloading, setIsLoading] = useState(false)
  const [CategoriesList, setCategoriesList] = useState([])

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const [searchTerm, setSearchTerm] = useState("")
  const dispatch = useDispatch()

  const fetchAllCategories = async () => {
    try {
      setIsLoading(true)
      axios
        .get(`${Url}/${apiVersion}/ticketCategories`, {
          headers: { Authorization: `Bearer ${access}` },
        })
        .then(response => {
          const AllCategories = response.data.data.categories

          setCategoriesList(AllCategories)
          setIsLoading(false)
        })
        .catch(err => {
          if (
            err.response?.status == 401 ||
            err.response?.status == 0 ||
            !err.response.status
          ) {
            dispatch(unAuthUser())
          } else {
            console.log(err)
            toast.error("Failed to fetch categories. Please try again later.")
          }
        })
    } catch (error) {
      console.error("Unexpected error:", error)
    }
  }
  useEffect(() => {
    fetchAllCategories()
  }, [])
  //search:

  const handleSearchChange = event => {
    setSearchTerm(event.target.value)
  }
  const filteredCategories = CategoriesList.filter(category =>
    category.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  if (!authorized) {
    return <UnAuthorizedPopup />
  }
  return (
    <div className=" user-setting-page ">
      {/* add new categories */}
      <Row className="d-flex justify-content-between align-items-center users-setting">
        <p>
          Ticket <span className="mx-1">&#62;</span>
          <span className="users-setting-span"> Categories</span>
        </p>
      </Row>
      <Row className="d-flex justify-content-between align-items-center mb-3">
        <Col className="input-group input-group-sm bg-white border-0">
          <div className="input-group-prepend bg-white border-0 users-setting">
            <p
              className="input-group-text bg-white border-0 d-flex gap-2 "
              id="inputGroup-sizing-sm"
            >
              <SearchIcon /> Search
            </p>
          </div>
          <input
            type="text"
            className="form-control bg-white border border-0 rounded rounded-2"
            aria-label="Small"
            aria-describedby="inputGroup-sizing-sm"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>

        <Col
          md={8}
          className="d-flex justify-content-end gap-2 align-items-center"
        >
          <button
            className="btn btn-secondary py-1 font-size-16 mb-0 rounded-2"
            onClick={toggleAdd}
          >
            {" "}
            + Add New Category
          </button>
          {/* <button onClick={handleEditClick} > + edit Categorie</button> */}
        </Col>
      </Row>

      {/* success message */}
      <div className="position-relative" aria-live="polite" aria-atomic="true">
        <div className="success-message">
          <Toast isOpen={showSuccessToast} fade={true}>
            <ToastBody className="success-message-bg">
              <h5 className="m-0 mb-2">Success</h5>
              <p className="m-0">Category Added Successfully</p>
            </ToastBody>
          </Toast>
        </div>
      </div>
      {/* Edit Message */}
      <div className="position-relative" aria-live="polite" aria-atomic="true">
        <div className="success-message">
          <Toast isOpen={showEditToast} fade={true}>
            <ToastBody className="edit-message-bg">
              <h5 className="m-0 mb-2">Edit</h5>
              <p className="m-0">Category edited Successfully</p>
            </ToastBody>
          </Toast>
        </div>
      </div>

      <CategoriesTable
        // CategoriesList={CategoriesList}
        CategoriesList={filteredCategories}
        setCategoriesList={setCategoriesList}
        isloading={isloading}
        toggleEdit={toggleEdit}
        toggleActive={toggleActiveCategory}
      />

      <AddCategories
        setShowSuccessToast={setShowSuccessToast}
        isOpen={modalAddNewCategory}
        toggle={toggleAdd}
        fetchAllCategories={fetchAllCategories}
      />
      <EditCategories
        setShowEditToast={setShowEditToast}
        isOpen={modalEditCategory}
        toggle={toggleEdit}
        Category={editCategory}
        fetchAllCategories={fetchAllCategories}
      />

      <StatusCategory
        isOpen={modalStatusCategory}
        toggle={toggleActiveCategory}
        category={activeCategory}
        fetchAllCategories={fetchAllCategories}
      />
    </div>
  )
}
