import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import DatePicker from "react-datepicker"
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Label,
  Legend,
  Tooltip,
} from "recharts"
import axios from "axios"
const { Url, type, version, apiVersion } = configs.client
import configs from "../../../config"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import UnAuthorizedPopup from "components/Common/UnAuthorized/UnAuthorizedPopup"
import { unAuthUser } from "store/actions"
import moment from "moment"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function TypeRequest({ startDate, endDate }) {
  const dispatch = useDispatch()

  const [isloading, setIsLoading] = useState(false)
  const [ticketRequestType, setTicketRequestType] = useState([])

  const { access, authorized } = useSelector(state => ({
    access: state.Login.userAccess,
    authorized: state.Login.authorized,
  }))

  const fetchAllRequestType = async () => {
    try {
      setIsLoading(true)
      const params = {
        startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
        endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
      }
      axios
        .get(`${Url}/${apiVersion}/ticket-dashboard/request-type`, {
          headers: { Authorization: `Bearer ${access}` },
          params: params,
        })
        .then(response => {
          const AllTicketRequestType = response.data.data

          const dataArray = Object.keys(AllTicketRequestType)
            .filter(key => key !== "total")
            .map(key => ({
              name: humanReadableNames[key] || key,
              value: AllTicketRequestType[key],
            }))

          setTicketRequestType(dataArray)
          setIsLoading(false)
        })
        .catch(err => {
          if (
            err.response?.status == 401 ||
            err.response?.status == 0 ||
            !err.response.status
          ) {
            dispatch(unAuthUser())
          } else {
            console.log(err)
            toast.error("Error: Unable to load request types.")
          }
        })
    } catch (error) {
      console.error("Unexpected error:", error)
    }
  }

  useEffect(() => {
    fetchAllRequestType()
  }, [startDate, endDate])

  const humanReadableNames = {
    RD0: "RD 0",
    EditRD0: "Edit RD 0",
    MissingData: "Missing Data",
    DesignReview: "Design Review",
    RD6: "RD 6",
    RD7: "RD 7",
    Finance: "Finance",
    Inspection: "Inspection",
    MALATHIssue: "MALATH Issue",
    MALATHComplaint: "MALATH Complaint",
    Other: "Other",
  }

  const COLORS = [
    "#536ED1",
    "#8FCD72",
    "#F9C857",
    "#EC6869",
    "#70C2D7",
    "#38A471",
    "#F7874E",
    "#9F5FB7",
    "#EE7ACC",
    "#556EC8",
    "#93CB71",
  ]
  const totalValue = ticketRequestType.reduce(
    (sum, entry) => sum + entry.value,
    0
  )

  const CustomTooltip = ({ payload, label }) => {
    if (!payload || !payload.length) return null
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#5A6778",
          color: "#FFFFFF",
          padding: "7px",
          borderRadius: "4px",
        }}
      >
        <p className="value m-0">{`${payload[0].value}`}</p>
      </div>
    )
  }

  const CustomLabel = ({ viewBox }) => {
    const { cx, cy } = viewBox

    return (
      <g>
        <text
          x={cx}
          y={cy - 10}
          textAnchor="middle"
          fill="#9291A5"
          fontSize="16px"
        >
          Total
        </text>

        <text
          x={cx}
          y={cy + 10}
          textAnchor="middle"
          fontSize="16"
          fontWeight="bold"
          fill="#6B7889"
        >
          {totalValue}
        </text>
      </g>
    )
  }

  if (!authorized) {
    return <UnAuthorizedPopup />
  }

  return (
    <>
      {isloading ? (
        <div className="loading d-flex justify-content-center align-items-center mb-2 p-3">
          <div className="spinner-border d-flex justify-content-center align-items-center"></div>
        </div>
      ) : ticketRequestType &&
        ticketRequestType.length > 0 &&
        ticketRequestType.some(item => item.value > 0) ? (
        <div className="d-flex justify-content-around align-items-center">
          <ResponsiveContainer width="40%" height={170}>
            <PieChart>
              <Pie
                data={ticketRequestType}
                cx={70}
                cy="50%"
                dataKey="value"
                stroke="none"
                outerRadius="93%"
              >
                {ticketRequestType.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>

          <div>
            <table className="">
              <tbody>
                {ticketRequestType.map((ticket, idx) => {
                  const percentage = Math.round(
                    (ticket.value / totalValue) * 100
                  )
                  return (
                    <tr key={idx}>
                      <td className="py-1 px-2 d-flex align-items-center">
                        <span
                          style={{
                            backgroundColor: COLORS[idx % COLORS.length],
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                        ></span>
                        <span>{ticket.name}</span>
                      </td>
                      <td className="py-1 px-2">{ticket.value}</td>
                      <td className="py-1 px-2">{percentage}%</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : ticketRequestType && ticketRequestType.length > 0 ? (
        <div className="d-flex justify-content-around align-items-center">
          {console.log("object", ticketRequestType)}
          <ResponsiveContainer width="40%" height={170}>
            <PieChart>
              <Pie
                data={[{ value: 1 }]}
                cx={65}
                cy="50%"
                dataKey="value"
                stroke="none"
                innerRadius={50}
                outerRadius={70}
              >
                <Cell fill="#e0e0e0" />
                <Label content={<CustomLabel />} />
              </Pie>
            </PieChart>
          </ResponsiveContainer>

          <div>
            <table className="">
              <tbody>
                {ticketRequestType.map((ticket, idx) => {
                  const percentage = Math.round(
                    (ticket.value / totalValue) * 100
                  )
                  return (
                    <tr key={idx}>
                      <td className="py-1 px-2 d-flex align-items-center">
                        s{" "}
                        <span
                          style={{
                            backgroundColor: COLORS[idx % COLORS.length],
                            width: "10px",
                            height: "10px",
                            borderRadius: "50%",
                            display: "inline-block",
                            marginRight: "10px",
                          }}
                        ></span>
                        <span>{ticket.name}</span>
                      </td>
                      <td className="py-1 px-2">{ticket.value}</td>
                      <td className="py-1 px-2">{percentage}%</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <p className="d-flex justify-content-center align-items-center mb-2 fw-bold p-3 fs-5">
          No data available
        </p>
      )}
    </>
  )
}
